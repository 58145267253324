import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import backendUrl from "../../config";

export const fetchDoctors = createAsyncThunk("fetchDoctorsResults", async (payload) => {
  const res = await fetch(`${backendUrl}/doctors/${payload.clinic_id}`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch doctors");
  }
});

const doctorSlice = createSlice({
  name: "doctors",
  initialState: {
    doctorsList: [],
    fetchingDoctors: true,
  },
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDoctors.fulfilled, (state, action) => {
      state.doctorsList = action.payload;
      if (state.fetchingDoctors) {
        state.fetchingDoctors = false;
      }
    });
    builder.addCase(fetchDoctors.pending, (state, action) => {
      state.doctorsList = {};
      if (!state.fetchingDoctors) {
        state.fetchingDoctors = true;
      }
    });
  },
});

export default doctorSlice.reducer;
