import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Autocomplete,
  TextField,
  Popover,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { menu } from "../../constants";
import { logout } from "../../redux/reducers/userSlice";

const Header = (props) => {
  const theme = useTheme();
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const { firstName, lastName } = useSelector((state) => state.user.userInfo);

  const dispatch = useDispatch();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const location = useLocation();
  const path = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const HandleSearchClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Func = (e) => {
    console.log(e.target.innerHTML);
  };

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { label: "City of God", year: 2002 },
    { label: "Se7en", year: 1995 },
    { label: "The Silence of the Lambs", year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: "Life Is Beautiful", year: 1997 },
    { label: "The Usual Suspects", year: 1995 },
    { label: "Léon: The Professional", year: 1994 },
    { label: "Spirited Away", year: 2001 },
    { label: "Saving Private Ryan", year: 1998 },
    { label: "Once Upon a Time in the West", year: 1968 },
    { label: "American History X", year: 1998 },
    { label: "Interstellar", year: 2014 },
    { label: "Casablanca", year: 1942 },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          backgroundColor: path === "/" ? "white" : "secondary.light",
          alignItems: isPhone ? "stretch" : "center",
        }}
      >
        <Toolbar
          variant="regular"
          sx={{
            height: 80,
            maxWidth: "1210px",
            // mx: "auto",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <img alt="logo-icon" width="69px" height="48px" src={require("../../assets/logo.png")} />
          </Link>
          <Box sx={{ width: "100px" }}></Box>
          {!isPhone && (
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
                fontSize: "20px",
                lineHeight: "22.48px",
                color: "primary.main",
              }}
            >
              <IconButton size="large" color="primary" onClick={HandleSearchClick}>
                <SearchIcon fontSize="inherit" />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Autocomplete
                  color={"primary"}
                  id="combo-box-demo"
                  options={top100Films}
                  sx={{
                    width: "290px",
                  }}
                  onInputChange={Func}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={(input) => input && input.focus()}
                      placeholder="Search"
                      value={params.year}
                      // InputProps={{
                      //     endAdornment: null
                      // }}
                    />
                  )}
                />
              </Popover>
              {menu.map((item) => (
                <Typography key={item} align={"center"} sx={{ mx: "12px" }}>
                  {item}
                </Typography>
              ))}
              {!isLoggedIn && (
                <Button
                  sx={{
                    mx: "12px",
                    width: "147px",
                    height: "45px",
                    borderRadius: 4,
                  }}
                  variant="contained"
                  onClick={() => navigate("/login")}
                >
                  Log in
                </Button>
              )}
              {isLoggedIn && (
                <Button
                  sx={{
                    mx: "12px",
                    width: "40px",
                    height: "45px",
                    borderRadius: 30,
                    fontSize: 32,
                    minWidth: "50px",
                  }}
                  variant="contained"
                >
                  {firstName[0]}
                  {lastName ? lastName[0] : ""}
                </Button>
              )}

              {isLoggedIn && (
                <Button
                  sx={{
                    mx: "12px",
                    width: "40px",
                    height: "45px",
                    borderRadius: 30,
                    fontSize: 12,
                    minWidth: "50px",
                  }}
                  variant="contained"
                  onClick={handleLogout}
                >
                  LOGOUT
                </Button>
              )}
              <Button
                sx={{
                  mx: "12px",
                  width: "40px",
                  height: "45px",
                  borderRadius: 30,
                  fontSize: 32,
                  minWidth: "50px",
                }}
                variant="contained"
                onClick={() => navigate("/registerclinic")}
              >
                +
              </Button>
            </Box>
          )}
          {isPhone && (
            <IconButton
              sx={{ marginLeft: "auto" }}
              color="primary"
              size="large"
              onClick={() => {
                props.openSidebar(true);
              }}
            >
              <MenuIcon fontSize="inherit" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
