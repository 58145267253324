import React from "react";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";

export default function FormHeader({ label }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "70px",
        backgroundColor: "primary.main",
        px: isPhone ? "25px" : "45px",
        borderRadius: "20px 20px 0px 0px",
      }}
    >
      <Typography variant={isPhone ? "h6" : "h5"} color="white">
        {label}
      </Typography>
    </Box>
  );
}
