import React from "react";

import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { CircleOutlined, Circle } from '@mui/icons-material';

export default function FormCheckbox({ id, name, value, onChange, label }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        justifyContent: "flex-start",
        mb: "1px",
        alignItems: "center",
        height: "50px",
      }}
    >
      <FormControlLabel
        sx={{
          mr: isPhone ? "5px" : "12px"
        }} 
        control={
          <Checkbox 
            icon={<CircleOutlined color="primary.main" />} 
            checkedIcon={<Circle color="primary.main" />} 
          />
        } 
      />
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"}>
        {label}
      </Typography>
    </Box>
  );
}
