import React from "react";
import FlexibleContainer from "../components/elements/flexibleContainer";

import LoginForm from "../components/loginForm";

export default function LoginPage() {
  return (
    <FlexibleContainer width="40%">
      <LoginForm />
    </FlexibleContainer>
  );
}
