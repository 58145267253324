import React, { useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ClinicCardDark({ clinic }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();

  const onClickBookAppointment = () => {
    navigate("/appointment-schedule", { state: { clinic_id: clinic.clinic_details[0].id }});
  }

  const onClickConstactClinic = () => {
    // navigate("/doctors", { state: { clinic: clinic }});
  }

  return (
    <Card 
      sx={{ 
        maxWidth: 300, 
        width: 300,
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",  
        borderRadius: "15px",
        backgroundColor: "primary.main", 
        }}
    >
      <CardMedia
        height="200"
        component="img"
        alt="clinic-image"
        image="/clinic.jpg"
        sx={{
          width: "90%",
          maxHeight: "250px",
          alignSelf: "center"
        }}
      />

      <CardContent 
        sx={{ 
          padding: "20px", 
          display: "flex", 
          flexDirection: "column",
          justifyContent: isPhone ? "center" : "flex-start"
        }}
      >
        <Typography variant="h6" color="white" sx={{ marginBottom: "5px" }}>
          Working hours:
        </Typography>
        <Box>
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px", marginRight: "5px" }}>
            Mon - Fri: 
          </Typography>
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
            {/* {clinic.clinic_details[0].working_hours_from} - {clinic.clinic_details[0].working_hours_to} */}
            7AM - 5PM
          </Typography>
        </Box>
        <Box
          sx={{mb: "20px"}}
        >
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px", marginRight: "5px" }}>
            Saturday: 
          </Typography>
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
            {/* {clinic.clinic_details[0].working_hours_from} - {clinic.clinic_details[0].working_hours_to} */}
            {/* {clinic.clinic_details[0].working_hours_from_sat} - {clinic.clinic_details[0].working_hours_to_sat} */}
            7AM - 2PM
          </Typography>
        </Box>
        <Button
          onClick={onClickBookAppointment} 
          sx={{
            width: "100%",
            height: "80px",
            borderRadius: 6,
            backgroundColor: "secondary.light",
            color: "primary.main",
            marginBottom: "10px",
            ':hover': {
              bgcolor: 'white',
            },
          }} 
          variant="outlined" 
        >
          Book appointment
        </Button>
        <Button
          onClick={onClickConstactClinic} 
          sx={{
            width: "100%",
            height: "80px",
            borderRadius: 6,
            borderColor: "white",
            borderWidth: "3px",
            color: "white",
            marginBottom: "10px",
            ":hover": {
              bgcolor: "secondary.light",
              color: "primary.main",
            },
          }} 
          variant="outlined" 
        >
          Contact clinic
        </Button>
      </CardContent>
    </Card>
  );
}
