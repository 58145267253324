import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme, } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      "&:hover": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "black",
        backgroundColor: "white"
      },
      "&.Mui-focused": {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#104583",
        backgroundColor: "white"
      },
      backgroundColor: "white",
      borderRadius: "18px",
      padding: "10px 20px",
    },
    "& .MuiFilledInput-input": {
      padding: "0",
    },
  },
}));

export default function Input({ id, name, value, onChange, label }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        py: "15px",
        justifyContent: "space-between",
        alignItems: "flex-start",
        mb: "1px",
        height: "112px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"}>
        {label}
      </Typography>
      <TextField
        id={id}
        className={classes.root}
        name={name}
        value={value}
        onChange={onChange}
        variant="filled"
        multiline
        rows={4}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ width: isPhone ? "60%" : "70%", heigth: "180px" }}
      />
    </Box>
  );
}
