import React, { useEffect } from "react";
import { useFormik } from "formik";

import Input from "../elements/input";
import { useDispatch, useSelector } from "react-redux";
import FormFooter from "../elements/formFooter";
import ContinueButton from "../elements/continueButton";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import FormHeader from "../elements/formHeader";
import { Typography, Box, Button } from "@mui/material";
import { login } from "../../redux/reducers/userSlice";
import BackendErrorMessage from "../backendErrorMessage";

export default function LoginForm() {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.user.error);
  const navigate = useNavigate();
  const location = useLocation();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      const { email, password } = values;
      dispatch(login({ email, password }));
    },
  });

  const onHandleClick = () => {
    navigate("/registeruser", { state: { fromRegisterClinic: location.pathname === "/registerclinic" ? true : null } });
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  return (
    <>
      {location.pathname === "/registerclinic" && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              fontSize: "30px",
              marginBottom: "10px",
            }}
          >
            Log in to register clinic
          </Typography>
        </Box>
      )}
      <form onSubmit={formik.handleSubmit}>
        <FormHeader label={"Log in"} />
        <Box bgcolor="primary.light" sx={{ px: "45px", py: "20px" }}>
          <Box
            bgcolor="white"
            sx={{
              borderRadius: "20px",
              px: "15px",
              py: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Don't have an account?</Typography>
            <Button
              onClick={onHandleClick}
              sx={{
                width: "147px",
                height: "45px",
                borderRadius: 4,
                backgroundColor: "primary.main",
                color: "white",
              }}
              variant="contained"
            >
              Register
            </Button>
          </Box>
        </Box>
        <Input label="Email address" name={"email"} onChange={formik.handleChange} value={formik.values.name} />
        <Input
          label="Password"
          type="password"
          name={"password"}
          onChange={formik.handleChange}
          value={formik.values.password}
        />

        <FormFooter justify="flex-end">
          <ContinueButton />
        </FormFooter>
      </form>
      {errorMessage && <BackendErrorMessage message={errorMessage} />}
    </>
  );
}
