import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ClinicCardDark from "../components/cards/ClinicCardDark";
import { useMediaQuery, useTheme, } from "@mui/material";
import FrontPageCarousel from "../components/carousel";
import TreatmentList from "../components/treatmentList/TreatmentList";
import CircularProgress from '@mui/material/CircularProgress';
import { fetchClinic } from "../redux/reducers/clinicSlice";

export default function ClinicDetails() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchClinic({ clinic_id: location.state.clinic_id }));
  }, [dispatch, location]);
  
  const clinic = useSelector((state) => state.clinics.selectedClinic);
  const fetchingClinicWithTreatments = useSelector((state) => state.clinics.fetchingClinicWithTreatments);

  const items = [
    { url: "/carousel/dental-1.jpg", title: "Filling" },
    { url: "/carousel/dental-2.jpg", title: "veneer" },
    { url: "/carousel/dental-3.jpg", title: "Whitening" },
    { url: "/carousel/dental-4.jpg", title: "Implants" },
  ];

  return (
    <Box 
      sx={{ 
        display: "flex", 
        flexDirection: "column", 
        position: "relative",
        marginTop: isPhone ? "20px" : "50px",
        mx: isPhone ? "10px" : "160px"
      }}
    >
      {fetchingClinicWithTreatments 
        && <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
      }

      {!fetchingClinicWithTreatments && (
        <Box 
          sx={{ 
            display: "flex", 
            flexDirection: isPhone ? "column-reverse" : "row", 
            justifyContent: "space-between",
            alignItems: isPhone ? "center" : "flex-start"
          }}
        >
          <Box 
            sx={{ 
              width: isPhone ? "100%" : 700,
              display: "flex", 
              flexDirection: "column", 
              mr: isPhone ? "0px" : "30px"
            }}
          >
            <Typography variant="h4" color="primary.main" sx={{ marginBottom: "5px" }}>
              Get the celebrity treatment with world-class service at {clinic.clinic_details[0].name}
            </Typography>
            <Typography variant="body3" color="primary.main" sx={{ fontSize: "25px", marginBottom: "5px", marginTop: "20px" }}>
              You're eligible for a Genius discount at {clinic.clinic_details[0].name}! 
              To save at this property, all you have to do is sign in.
            </Typography>
            <br />
            <Typography variant="body3" color="primary.main" sx={{ fontSize: "25px", marginTop: "15px" }}>
              {clinic.clinic_details[0].description}
            </Typography>
          </Box>
          <ClinicCardDark clinic={clinic}/>
        </Box>
      )}
      {!fetchingClinicWithTreatments && (
        <Box
          sx={{ 
            display: "flex", 
            flexDirection: "column", 
          }}
        >
          <FrontPageCarousel items={items} isBelowMd={isPhone} />
          <TreatmentList treatments={clinic.categories_with_types} />
          {/* <Comments /> */}
        </Box>
      )}
    </Box>
  );
}
