import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import SearchBox from "../components/common/SearchBox/SearchBox";
import React from "react";
import FrontpageAestheticBlock from "../components/forntpageAestheticBlock/index.js";
import FrontpageDentalBlock from "../components/forntpageDentalBlock/index.js";

const Home = () => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const Text = (props) => (
    <Box
      sx={{
        position: "absolute",
        left: isTablet || isPhone ? "60px" : "calc(35% - 270px)",
        top: "15%",
      }}
    >
      <Typography
        sx={{
          fontSize: props.title,
          lineHeight: "93.68%",
          color: "primary.main",
        }}
      >
        Discover
        <br /> 
        the best
        <br />
        treatments
      </Typography>
      <Typography
        sx={{
          fontFamily: "Montserrat-Regular",
          fontSize: props.subtitle,
          lineHeight: "93.68%",
          color: "primary.main",
          fontWeight: "400",
          mt: "15px",
        }}
      >
        ...and discover the
        <br />
        most engaging places
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", position: "relative" }}>
        {!isPhone && (
          <Box sx={{ backgroundColor: "#BAF0F8", width: "55vw" }}>
            <Text title="6.3vw" subtitle="2.1vw" />
          </Box>
        )}
        <div style={{ width: "auto" }}>
          <img
            alt="logo-icon"
            src={require("../assets/wallpapers/wallpaper-1.png")}
            style={{ width: "100%", height: "auto" }}
          />
          {isPhone && (
            <div
              style={{
                background: "rgba(186, 240, 248, 0.8)",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Text title="11vw" subtitle="3.5vw" />
            </div>
          )}
        </div>
        <Container
          sx={{
            width: "100%",
            display: useMediaQuery(theme.breakpoints.between("xs", "md")) ? "" : "flex",
            justifyContent: useMediaQuery(theme.breakpoints.between("xs", "sm")) ? "" : "center",
            position: "absolute",
            bottom: useMediaQuery(theme.breakpoints.between("xs", "md")) ? "-340px" : "-40px",
            left: "0",
            right: "0",
          }}
        >
          <SearchBox />
        </Container>
      </Box>
      <FrontpageAestheticBlock />
      <FrontpageDentalBlock />
    </Box>
  );
};

export default Home;
