import React from "react";
import { Button } from "@mui/material";

export default function ContinueButton({ handleClick, type }) {
  return (
    <Button
      type="submit"
      onClick={handleClick}
      sx={{
        width: "147px",
        height: "45px",
        borderRadius: 4,
        backgroundColor: "secondary.main",
        color: "white",
      }}
      variant="contained"
    >
      Continue
    </Button>
  );
}
