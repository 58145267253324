import { 
  Box, 
  Typography, 
  useMediaQuery, 
  useTheme, 
  Grid,
  CircularProgress
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ClinicCardDark from "../components/cards/ClinicCardDark";
import { fetchClinic } from "../redux/reducers/clinicSlice";
import { fetchDoctors } from "../redux/reducers/doctorSlice";

export default function DoctorsListPage() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log(location.state)
    dispatch(fetchDoctors({ clinic_id: location.state.clinic_id }));
    dispatch(fetchClinic({ clinic_id: location.state.clinic_id }));
  }, [dispatch, location]);

  const clinic = useSelector((state) => state.clinics.selectedClinic);
  const fetchingClinicWithTreatments = useSelector((state) => state.clinics.fetchingClinicWithTreatments);
  const doctors = useSelector((state) => state.doctors.doctorsList);
  const fetchingDoctors = useSelector((state) => state.doctors.fetchingDoctors);

  return (
    <Box 
      sx={{ 
        display: "flex", 
        flexDirection: isPhone ? "column" : "row", 
        position: "relative",
        marginTop: isPhone ? "20px" : "50px",
        mx: isPhone ? "10px" : "160px", 
        justifyContent: "space-around",
        alignItems: isPhone ? "center" : "flex-start"
      }}
    >
      {fetchingDoctors 
        ? <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
        : doctors?.length === 0 
          ? <Typography variant="h6" color="black">No doctors currently listed for the selected clinic</Typography>
          : <Box
              sx={{
                maxWidth: "calc(100% - 350px)",
                mb: "30px"
              }}
            >
              <Grid 
                container 
                //rowSpacing={1} 
                //spacing={8}
                columns={12}
              >
              {doctors?.map((d, key) => (
                <Grid item xs={12} md={12} lg={6} key={key}>
                  <Box
                    sx={{ 
                      display: "flex",
                      flexDirection: "column",
                      alignItems: isPhone ? "center" : "flex-start",
                      height: "320px"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", 
                        height: isPhone ? 200 : 220,
                        width: isPhone ? 200 : 220,
                        borderRadius: 5,
                        border: 4,
                        borderColor: "primary.main",
                      }}
                    >
                      <img
                        alt="cash-icon"
                        height="80px"
                        style={{margin: "6px"}}
                        src={require("../assets/icons/user-icon.png")}
                      />
                    </Box>
                    <Typography variant="h6" color="black">
                      {d.full_name}
                    </Typography>
                    <Typography variant="body3" color="black">
                      {d.proffesion}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
      }
      {fetchingClinicWithTreatments
        ? <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box> 
        : <Box><ClinicCardDark clinic={clinic}/></Box>
      }
      
    </Box>
  );
}