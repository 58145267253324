import React from "react";
import { Box } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export default function MainContainer({ children }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: isPhone ? "10px" : "80px",
        marginBottom: "20px",
        maxWidth: "1210px",
        width: "95%",
        mx: "auto",
      }}
    >
      {children}
    </Box>
  );
}
