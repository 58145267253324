import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { authAxios } from "../../axiosInterceptoprs";
import backendUrl from "../../config";

export const registerUser = createAsyncThunk("registerUser", async (formData, { rejectWithValue }) => {
  try {
    const res = await axios.post(`${backendUrl}/email-exists`, {
      email: formData.email,
    });

    if (!res.data.email_exists) {
      const registerUserResponse = await axios.post(`${backendUrl}/registeruser`, formData);
      localStorage.setItem("token", registerUserResponse.data.token.original.access_token);
      return registerUserResponse.data;
    } else {
    }
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getLoggedInUser = createAsyncThunk("getLoggedInUser", async (_, { rejectWithValue }) => {
  const res = await authAxios.get(`${backendUrl}/me`);

  return res.data;
});

export const login = createAsyncThunk("login", async (formData, { rejectWithValue }) => {
  try {
    const res = await axios.post(`${backendUrl}/login`, formData);
    localStorage.setItem("token", res.data.token.original.access_token);
    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data.error);
  }
});

const registerUserSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    userInfo: {}, // for user object
    loggedIn: false,
    userToken: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
  },
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem("token");
      state.loggedIn = false;
      state.userInfo = {};
    },
    setIsloading: (state, action) => {
      state.loading = true;
    },
    setUser: (state, action) => {
      state.userInfo.firstName = action.payload.clinic.name;
      state.userInfo.email = action.payload.clinic.email;
      state.loggedIn = true;
      state.success = true;
    },
  },
  extraReducers: {
    [registerUser.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.loading = false;
      state.success = true;
      state.userInfo = {
        email: payload.email,
        firstName: payload["first name"],
        lastName: payload["last name"],
      };
      state.loggedIn = true;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.userInfo = {};
      state.loggedIn = false;
      state.error = payload.message;
    },
    [getLoggedInUser.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getLoggedInUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.loggedIn = true;
      state.userInfo = {
        email: payload.email,
        firstName: payload["first_name"],
        lastName: payload["last_name"],
        clinics: payload.clinics,
      };
    },
    [getLoggedInUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.loggedIn = false;
      state.userInfo = {};
    },

    [login.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.loggedIn = true;
      state.userInfo = {
        email: payload.user.email,
        firstName: payload.user["first_name"],
        lastName: payload.user["last_name"],
      };
    },
    [login.rejected]: (state, { payload }) => {
      state.success = false;
      state.loggedIn = false;
      state.userInfo = {};
      state.error = payload;
    },
  },
});
export const { logout, setIsloading, setUser } = registerUserSlice.actions;
export default registerUserSlice.reducer;
