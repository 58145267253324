import React from "react";
import { Box } from "@mui/material";
import { WidthNormalOutlined } from "@mui/icons-material";

export default function FlexibleContainer({ children, width }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "80px",
        marginBottom: "20px",
        maxWidth: "810px",
        width: width,
        mx: "auto",
      }}
    >
      {children}
    </Box>
  );
}
