import React, { useState } from "react";
import { Box, Typography, IconButton, Select, MenuItem } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSelect-outlined": {
      padding: "9px",
    },
  },
}));

export default function Dropdown({ id, name, defaultValue = "", value, onChange, label, options }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);    
  }

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        justifyContent: "space-between",
        mb: "1px",
        alignItems: "center",
        height: isPhone ? "60px" : "70px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"} sx={{}}>
        {label}
      </Typography>
      <Select
        labelId="dropdown-select-label"
        id="dropdown-select"
        name={name}
        className={classes.root}
        value={value}
        defaultValue={defaultValue}
        variant="outlined"
        onClick={handleClick}
        open={open}
        IconComponent={() => {
          return (
            <IconButton
              color="primary"
              onClick={handleClick}
            >
              <img
                alt="dropdown-icon"
                style={{
                  width: "20px",
                  height: "10.96px",
                }}
                src={require("../../assets/icons/arrow-down.png")}
              />
            </IconButton>
          )
        }}
        sx={{
          backgroundColor: "white",
          borderRadius: "50px",
          border: 0,
          padding: "10px 20px",
          width: isPhone ? "60%" : "70%",
          height: "43px",
        }}
        onChange={onChange}
      >
        {options.length > 0 &&
          options.map((o) => {
            return (
              <MenuItem key={o.id} value={o.id}>
                {o.name || o.value}
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
}
