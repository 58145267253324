import React from "react";

import { Button, Card } from "@mui/material";
import SelectTreatmentType from "../Items/SelectTreatmentType";
import SelectCountry from "../Items/SelectCountry";
import SelectCity from "../Items/SelectCity";

import SearchIcon from "@mui/icons-material/Search";

const Desktop = ({ isBelowMd, handleClick }) => {
    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexGrow: 1,
                borderRadius: "20px",
                width: "100%",
                maxWidth: "1200px",
                minHeight: "100px",
            }}
        >
            <SelectTreatmentType
                    sx={{
                        maxWidth: "280px",
                        color: "primary.main",
                        width: "27%"
                    }}
                    isBelowMd={isBelowMd}
            ></SelectTreatmentType>

            <SelectCountry
                sx={{
                    maxWidth: "280px",
                    color: "primary.main",
                    width: "27%"
                }}
                isBelowMd={isBelowMd}
            ></SelectCountry>

            <SelectCity
                sx={{
                    maxWidth: "280px",
                    color: "primary.main",
                    width: "27%"
                }}
                isBelowMd={isBelowMd}
            ></SelectCity>
            
            <Button
                variant="contained"
                color="primary"
                style={{ maxWidth: "300px", flexGrow: 1, width: "19%" }} // maxWidth: "378px"
                startIcon={<SearchIcon />}
                onClick={handleClick}
                sx={{ borderRadius: 0 }}
            >
                Search
            </Button>
        </Card>
    );
};

export default Desktop;
