import React from "react";

import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";

export default function FormCheckbox({ id, name, value, onChange, label, height, isMessage }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        py: "10px",
        justifyContent: "space-between",
        mb: "1px",
        alignItems: "center",
        height: height ? height : "30px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"}>
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: isMessage ? "flex-start" : "center",
          width: isPhone ? "60%" : "70%",
          height: "inherit",
          overflowY: isMessage ? "auto" : "unset"
        }}
      >
        <Typography 
          color="primary.main" 
          variant="p"
          sx={{
            fontSize: isPhone ? "unset" : "1.25rem"
          }}
        >
          {value}
        </Typography>
      </Box>

    </Box>
  );
}
