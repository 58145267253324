import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#104583",
      light: "rgba(116, 155, 214, 0.33)",
    },
    secondary: {
      main: "#749BD6",
      light: "#BAF0F8",
    },
  },
  typography: {
    fontFamily: "Montserrat-SemiBold",
    button: {
      fontFamily: "Montserrat-SemiBold",
      fontSize: 20,
      lineHeight: "22.48px",
      textTransform: "none",
    },
    input: {
      fontFamily: "Montserrat-SemiBold",
    },
  },
});

export default theme;
