import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { authAxios } from "../../axiosInterceptoprs";
import backendUrl from "../../config";

export const registerClinic = createAsyncThunk("registerClinic", async (formData, { rejectWithValue }) => {
  try {
    const res = await axios.post(`${backendUrl}/registerclinic`, formData);
    localStorage.setItem("token", res.data.token.original.access_token);

    return res.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const registerClinicSlice = createSlice({
  name: "registerClinic",
  initialState: {
    loading: false,
    info: {
      name: "",
      email: "",
      registration_status: null,
    },

    error: null,
    success: false, // for monitoring the registration process.
  },
  reducers: {},
  extraReducers: {
    [registerClinic.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [registerClinic.fulfilled]: (state, { payload }) => {
      const { email, name, registration_status } = payload.clinic;
      state.success = true;
      state.loading = false;
      state.error = null;
      state.info = {
        email,
        name,
        registration_status,
      };
    },
    [registerClinic.rejected]: (state, { payload }) => {},
  },
});

export default registerClinicSlice.reducer;
