import axios from "axios";
import backendUrl from "./config";

const token = localStorage.getItem("token");

export const authAxios = axios.create({
  baseURL: backendUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      throw new Error(error.response.data.message);
    }
  }
);
