import React from "react";

import { Box } from "@mui/material";

const Menu = () => {
    return (
        <Box
            sx={{
                display: "flex",
                fontFamily: "Montserrat-SemiBold",
                flexGrow: 1,
                px: 2,
                py: 0,
            }}
        >
            <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                <Box sx={{ fontSize: "1.3rem", my: 2 }}>Aesthetic treatments</Box>
                <Box sx={{ fontSize: "1.3rem", my: 2 }}>Dental treatments</Box>
                <Box sx={{ fontSize: "1.3rem", my: 2 }}>Special offers</Box>
                <Box sx={{ fontSize: "1.3rem", mt: 2 }}>
                    Perfect destinations
                </Box>
            </Box>
        </Box>
    );
};

export default Menu;
