import { Routes as RoutesReact, Route } from "react-router-dom";
import React from "react";

import App from "../../App";
import Home from "../../pages/Home";
import Test from "../../pages/Test";
import ClinicsListPage from "../../pages/ClinicsListPage";
import ClinicDetails from "../../pages/ClinicDetails";
import DoctorsListPage from "../../pages/DoctorsListPage";
import RegisterClinicPage from "../../pages/RegisterClinic";
import RegisterUserPage from "../../pages/RegisterUser";
import AppointmentSchedule from "../../pages/appointment/Schedule";
import AppointmentPatientDetails from "../../pages/appointment/PatientDetails";
import AppointmentBillingDetails from "../../pages/appointment/BillingDetails";
import AppointmentConfirm from "../../pages/appointment/Confirm";
import AppointmentDetails from "../../pages/appointment/Details";
import LoginPage from "../../pages/LoginPage";

const Routes = () => {
  return (
    <RoutesReact>
      <Route element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="/clinics" element={<ClinicsListPage />} />
        <Route path="/clinic-details" element={<ClinicDetails />} />
        <Route path="/doctors" element={<DoctorsListPage />} />
        <Route path="/registerclinic" element={<RegisterClinicPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registeruser" element={<RegisterUserPage />} />
        <Route path="/appointment-schedule" element={<AppointmentSchedule />} />
        <Route path="/appointment-patient" element={<AppointmentPatientDetails />} />
        <Route path="/appointment-billing" element={<AppointmentBillingDetails />} />
        <Route path="/appointment-confirm" element={<AppointmentConfirm />} />
        <Route path="/appointment-details" element={<AppointmentDetails />} />

        <Route path="test" element={<Test />} />
      </Route>
      <Route path="*" element={<div style={{ margin: "10px" }}>404 Not found</div>} />
    </RoutesReact>
  );
};

export default Routes;
