import React, { useState } from "react";

import { Box, Typography, TextField } from "@mui/material";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { useMediaQuery, useTheme, } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      padding: "10px 20px",
    },
    "& .MuiInput-root": {
      backgroundColor: "white",
      borderRadius: "50px",
      border: 0,
      padding: "10px 20px",
      height: "40px",
    },
  },
}));

export default function BasicDatePicker({ id, name, value, onChange, label, options }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const [tvalue, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        justifyContent: "space-between",
        mb: "1px",
        alignItems: "center",
        height: isPhone ? "60px" : "70px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"} sx={{}}>
        {label}
      </Typography>
      {isPhone && (
        <MobileDatePicker
          className={classes.root}
          value={tvalue}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          InputProps={{
            disableUnderline: true
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
              placeholder="mm\dd\yyyy"
              sx={{ 
                width: "60%",
                maxWidth: "60%",
                backgroundColor: "white",
                borderRadius: "50px",
                border: 0,
                height: "40px", 
              }}
            />
          )}
        />
      )}
      {!isPhone && (
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          className={classes.root}
          // variant="outlined"
          value={tvalue}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          InputProps={{
            disableUnderline: true
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                variant="standard"
                onClick={(e) => setOpen(true)}
                sx={{ 
                  width: "70%",
                  backgroundColor: "white",
                  borderRadius: "50px",
                  border: 0,
                  height: "40px", 
                }}
              />
            );
          }}
        />
      )}
    </Box>
  );
}
