import React from "react";
import { Button, Box, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function FileInput({ value, onChange }) {
  return (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <Button component="label">
        <span style={{ marginRight: "10px", fontSize: "40px", fontWeight: "700" }}>+</span>
        <span>Attach the document</span>

        <input type="file" hidden onChange={(e) => onChange("file", e.currentTarget.files[0])} />
      </Button>
      {value && <Typography>{value.name}</Typography>}
      {value && (
        <IconButton onClick={() => onChange("file", "")}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
}
