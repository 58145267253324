import { configureStore } from "@reduxjs/toolkit";
import cityReducer from "./reducers/citySlice";
import countryReducer from "./reducers/countrySlice";
import categoryReducer from "./reducers/treatmentSlice";
import clinicReducer from "./reducers/clinicSlice";
import doctorReducer from "./reducers/doctorSlice";
import userReducer from "./reducers/userSlice";
import registerClinicReducer from "./reducers/registerClinicSlice";

export const store = configureStore({
  reducer: {
    city: cityReducer,
    countries: countryReducer,
    categories: categoryReducer,
    clinics: clinicReducer,
    doctors: doctorReducer,
    user: userReducer,
    registerClinic: registerClinicReducer,
  },
});
