import React from "react";

import Drawer from "@mui/material/Drawer";

import Content from "./Content/Content";

const Sidebar = (props) => {
    const toggleDrawer =
        (open) => (event) => {
            if (
                event.type === "keydown" &&
                ((event).key === "Tab" ||
                    (event).key === "Shift")
            ) {
                return;
            }
            props.toggleSidebar(open);
        };

    const list = (anchor) => (
        <Content anchor={anchor} toggleDrawer={toggleDrawer} />
    );

    return (
        <div>
            <Drawer
                anchor={props.anchor}
                open={props.status}
                onClose={toggleDrawer(false)}
            >
                {list(props.anchor)}
            </Drawer>
        </div>
    );
};

export default Sidebar;
