import React from "react";

import { Box, Typography, TextField } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      "&:hover": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "black",
        backgroundColor: "white"
      },
      "&.Mui-focused": {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#104583",
        backgroundColor: "white"
      },
      backgroundColor: "white",
      borderRadius: "50px",
      padding: "10px 20px",
    },
    "& .MuiFilledInput-input": {
      padding: "0",
    },
  },
}));

export default function Input({ id, name, value, onChange, label }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        py: isPhone? "10px" : "0px",
        justifyContent: "space-between",
        mb: "1px",
        alignItems: isPhone ? "flex-start" : "center",
        height: isPhone ? "100px" : "70px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"}>
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isPhone ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: isPhone ? "60%" : "70%",
          height: "95%"
        }}
      >
        <TextField
          id={id}
          className={classes.root}
          name={name}
          value={value}
          onChange={onChange}
          variant="filled"
          placeholder="First Name"
          InputProps={{
            disableUnderline: true,
          }}
          style={{ width: isPhone ? "100%" : "45%", height: "40px" }}
        />
        <TextField
          id={id}
          className={classes.root}
          name={name}
          value={value}
          onChange={onChange}
          variant="filled"
          placeholder="Last Name"
          InputProps={{
            disableUnderline: true,
          }}
          style={{ width: isPhone ? "100%" : "45%", height: "40px" }}
        />
      </Box>
    </Box>
  );
}
