import React, { useEffect } from "react";
import { Box, Typography, FormControlLabel, Checkbox, Link } from "@mui/material";
import { CircleOutlined, Circle } from '@mui/icons-material';
import { useMediaQuery, useTheme, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/elements/mainContainer";
import FormHeader from "../../components/elements/formHeader";
import FormFooter from "../../components/elements/formFooter";
import ContinueButton from "../../components/elements/continueButton";
import BackButton from "../../components/elements/backButton";
import FormInfo from "../../components/elements/formInfo";

export default function Confirm() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();

  const handleClickContinue = () => {
    navigate("/appointment-details");
  }

  const handleClickBack = () => {
    navigate("/appointment-billing");
  }

  return (
    <MainContainer>
      <FormHeader label={"Schedule Your Appointment"}/>
      <Box 
        sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: isPhone ? "60px" : "70px",
          backgroundColor: "white",
          border: 1,
          borderColor: "primary.main",
          px: isPhone ? "25px" : "45px",
          mb: "1px"
        }}
      >
        <Typography variant={isPhone ? "p" : "h5"} color="primary.main">
          4 - Confirm appointment
        </Typography>
      </Box>
      <FormInfo label="Date" value="30.10.2023."/>
      <FormInfo label="Time" value="Anytime"/>
      <FormInfo label="For" value="Compose filling"/>
      <FormInfo label="Message" isMessage height="80px" 
        value="ASDASASASA  ASASDASDASD loASDASDDrem 
        ipsum lorem ipsum lorem ipsum ASDASASASA ASASD 
        ASASDASDASD loASDASDDrem ipsum lorem ipsum 
        lorem ipsum ASDASASASA ASASD  
        loASDASDDrem  lorem ipsum lorem ipsum 
        ASDASASASA ASASD  loASDASDDrem 
        ipsum lorem ipsum lorem  
        ASASD  loASDASDDrem ipsum 
        lorem ipsum lorem ipsum ASDASASASA ASASD 
        ASASDASDASD loASDASDDrem ipsum lorem ipsum 
        lorem ipsum  ASASD ASASDASDASD loASDASDDrem 
        ipsum lorem ipsum  ASDASASASA ASASD ASASDASDASD 
        loASDASDDrem lorem ipsum ASDASASASA ASASD 
        ASASDASDASD ipsum lorem ipsum lorem ipsum
        "
      />
      <Box
        sx={{
          height: "30px",
          backgroundColor: "primary.main",
        }}
      >
      </Box>
      <FormInfo label="Name" value="Lorem ipsum"/>
      <FormInfo label="E-mail Address" value="email@email.e" />
      <FormInfo label="Phone number" value="+381 123123123"/>
      <FormInfo label="Contact via" value="E-mail"/>
      <Box
        bgcolor="primary.light"
        sx={{
          display: "flex",
          flexDirection: "column",
          px: isPhone ? "25px" : "45px",
          height: "75px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "35px"
          }}
        >
          <FormControlLabel
            sx={{
              mr: isPhone ? "5px" : "12px"
            }} 
            control={
              <Checkbox 
                icon={<CircleOutlined color="primary.main" />} 
                checkedIcon={<Circle color="primary.main" />} 
              />
            } 
          />
          <Typography 
            color="primary.main" 
            variant={isPhone ? "p" : "h6"}
            sx={{
              fontSize: isPhone ? "inherit" : "1rem"
            }}
          >
            I agree to the <Link href="#">Terms and Conditions</Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "35px"
          }}
        >
          <FormControlLabel
            sx={{
              mr: isPhone ? "5px" : "12px"
            }} 
            control={
              <Checkbox 
                icon={<CircleOutlined color="primary.main" />} 
                checkedIcon={<Circle color="primary.main" />} 
              />
            } 
          />
          <Typography 
            color="primary.main" 
            variant={isPhone ? "p" : "h6"}
            sx={{
              fontSize: isPhone ? "inherit" : "1rem"
            }}
          >
            I agree to the <Link href="#">Privacy Policy</Link>
          </Typography>
        </Box>
      </Box>
      <FormFooter>
        <BackButton handleClick={handleClickBack} />
        <ContinueButton handleClick={handleClickContinue} />
      </FormFooter>
    </MainContainer>
  );
}
