import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { setSelectedCity, setSelectedCityDisplay, setIsCitySelected } from "../../../../redux/reducers/countrySlice";
import { fetchFilters } from "../../../../redux/reducers/clinicSlice";
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const SelectCity = (props) => {
    const selectedCityDisplay = useSelector((state) => state.countries.selectedCityDisplay);
    const isCitySelected = useSelector((state) => state.countries.isCitySelected);
    const filters = useSelector((state) => { if (state.clinics.fetchingFilters) { return null } else { return state.clinics.filters } });
    const isLoading = useSelector((state) => state.clinics.fetchingFilters );
    const treatmentId = useSelector((state) => state.categories.selectedTreatmentTypeId);
    const countryId = useSelector((state) => state.countries.selectedCountryId);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const itemHandleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        dispatch(setIsCitySelected(true));
        dispatch(setSelectedCityDisplay(event.target.innerText));
        dispatch(setSelectedCity(event.target.innerText));
        dispatch(fetchFilters({ treatment_type_id: treatmentId, country_id: countryId, city: event.target.innerText }));
    };
    const handleCancelClick = () => {
        dispatch(setIsCitySelected(false));
        dispatch(setSelectedCityDisplay("Select a City"));
        dispatch(setSelectedCity("All"));
        dispatch(fetchFilters({ treatment_type_id: treatmentId, country_id: countryId, city: "All" }));
    };

    return (
        <Box
            sx={{
                display: "flex",
                fontFamily: "Montserrat-Regular",
                flexGrow: 1,
                px: 2,
                py: 1.5,
                ...props.sx,
            }}
        >
            <Box sx={{ flexGrow: 1, minWidth: "0" }}>
                <Box style={{ fontSize: "1.1rem", margin: "6px 0px 10px" }}>City</Box>
                <Box 
                    style={{ 
                        fontSize: "1.5rem",
                        width: "calc(80%)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >{selectedCityDisplay}</Box>
            </Box>
            <Box
                sx={{ 
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column" 
                }}
            >
                <IconButton
                    color="primary"
                    sx={{
                        alignSelf: props.isBelowMd ? "center" : "start",
                        m: props.isBelowMd ? 0 : 0.2,
                    }}
                    onClick={handleClick}
                >
                    <img
                        alt="dropdown-icon"
                        style={{
                            width: "20px",
                            height: "11.36px",
                        }}
                        src={require("../../../../assets/icons/arrow-down.png")}
                        />
                </IconButton>
                {isCitySelected &&
                    <IconButton
                        sx={{
                            alignSelf: props.isBelowMd ? "center" : "end",
                            m: props.isBelowMd ? 0 : 0.2,
                        }}
                        onClick={handleCancelClick}
                    >
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                }
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                classes={{
                    paper: 'MuiCustom-menu'
                }}
            > 
                {isLoading 
                    ?   <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
                    :   filters?.cities.map((c) => (
                            <MenuItem 
                                onClick={itemHandleClose} 
                                key={c.city} 
                                value={c.city}
                            >
                                {c.city}
                            </MenuItem>
                        ))
                }
                
            </Menu>
        </Box>
    );
};

export default SelectCity;
