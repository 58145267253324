import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import backendUrl from "../../config";

export const fetchClinics = createAsyncThunk("fetchClinicsResults", async (payload) => {
  const res = await fetch(
    `${backendUrl}/clinics-search/treatment_type_id/${payload.treatment_type_id}/country_id/${payload.country_id}/city/${payload.city}`
  );

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch clinics");
  }
});

export const fetchClinic = createAsyncThunk("fetchClinicResults", async (payload) => {
  const res = await fetch(`${backendUrl}/clinics/${payload.clinic_id}`);

  const temp = await res.json();

  const categories = Object.keys(temp?.categories_with_types).map((category) => {
    const objects = temp?.categories_with_types[category];
    const array = {
      category,
      treatment_type: objects,
    };
    return array;
  });

  const data = {
    categories_with_types: categories,
    clinic_details: temp.clinic_details,
    certificates: temp.certificates,
    additional_services: temp.additional_services,
  };

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch clinic");
  }
});

export const fetchFilters = createAsyncThunk("fetchFiltersResults", async (payload) => {
  const res = await fetch(
    `${backendUrl}/filters/treatment_type_id/${payload.treatment_type_id}/country_id/${payload.country_id}/city/${payload.city}`
  );

  const temp = await res.json();

  const categories = Object.keys(temp?.categories_with_types).map((category) => {
    const objects = temp?.categories_with_types[category];
    const array = {
      category,
      treatment_type: objects,
    };
    return array;
  });

  const data = {
    categories_with_types: categories,
    cities: temp.cities,
    countries: temp.countries,
  };

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch filters");
  }
});

const clinicSlice = createSlice({
  name: "clinics",
  initialState: {
    clinicsList: [],
    fetchingClinics: false,
    selectedClinic: [],
    fetchingClinicWithTreatments: true,
    filters: [],
    fetchingFilters: true,
  },
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClinics.fulfilled, (state, action) => {
      state.clinicsList = action.payload;
      if (state.fetchingClinics) {
        state.fetchingClinics = false;
      }
    });
    builder.addCase(fetchClinics.pending, (state, action) => {
      state.clinicsList = {};
      if (!state.fetchingClinics) {
        state.fetchingClinics = true;
      }
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
      if (state.fetchingFilters) {
        state.fetchingFilters = false;
      }
    });
    builder.addCase(fetchFilters.pending, (state, action) => {
      state.filters = {};
      if (!state.fetchingFilters) {
        state.fetchingFilters = true;
      }
    });
    builder.addCase(fetchClinic.fulfilled, (state, action) => {
      state.selectedClinic = action.payload;
      if (state.fetchingClinicWithTreatments) {
        state.fetchingClinicWithTreatments = false;
      }
    });
    builder.addCase(fetchClinic.pending, (state, action) => {
      state.selectedClinic = {};
      if (!state.fetchingClinicWithTreatments) {
        state.fetchingClinicWithTreatments = true;
      }
    });
  },
});

export default clinicSlice.reducer;
