import React from "react";
import FlexibleContainer from "../components/elements/flexibleContainer";
import MainContainer from "../components/elements/mainContainer";

import LoginForm from "../components/loginForm";
import RegisterUserForm from "../components/registerUserForm";

export default function RegisterUserPage() {
  return (
    <MainContainer>
      <RegisterUserForm />
    </MainContainer>
  );
}
