import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/elements/mainContainer";
import FormHeader from "../../components/elements/formHeader";
import FormFooter from "../../components/elements/formFooter";
import ContinueButton from "../../components/elements/continueButton";
import BackButton from "../../components/elements/backButton";
import Input from "../../components/elements/input";

export default function BillingDetails() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();

  const handleClickContinue = () => {
    navigate("/appointment-confirm");
  }

  const handleClickBack = () => {
    navigate("/appointment-patient");
  }

  return (
    <MainContainer>
      <FormHeader label={"Schedule Your Appointment"}/>
      <Box 
        sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: isPhone ? "100px" : "70px",
          backgroundColor: "white",
          border: 1,
          borderColor: "primary.main",
          px: "45px",
          mb: "1px"
        }}
      >
        <Typography variant="h5" color="primary.main">
          3 - Billing details
        </Typography>
      </Box>
      <Input label="Name on the card"/>
      <Input label="Credit or Debit card" placeholder={"Enter card number"}/>
      <Input label="Expiry Date" placeholder={"MM/YY"}/>
      <Input label="Security Code (CVV)" placeholder={"Enter CVV"}/>
      <Input label="Address"/>
      <Input label="Postal code"/>
      <FormFooter>
        <BackButton handleClick={handleClickBack} />
        <ContinueButton handleClick={handleClickContinue} />
      </FormFooter>
    </MainContainer>
  );
}
