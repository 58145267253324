import React from "react";
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { menu as menuLeft, menuFooter as menuRight } from "../../constants";

const Footer = (props) => {
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box
                sx={{
                    display: "flex",
                    flexFlow: isPhone ? "column" : "row",
                    backgroundColor: "white",
                    height: 290,
                    maxWidth: "1210px",
                    width: "95%",
                    alignItems: "center",
                    justifyContent: isPhone ? "center" : "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: isPhone ? "center" : "start",
                        fontSize: "20px",
                        lineHeight: "22.48px",
                        color: "primary.main",
                    }}
                >
                    {menuLeft.map((item) => (
                        <Typography key={item} sx={{ mx: "12px" }}>
                            {item}
                        </Typography>
                    ))}
                    <Box
                        sx={{
                            display: isPhone ? "block" : "none",
                            marginTop: "10px",
                            marginBottom: "10px",
                            width: "60px",
                            borderBottom: 4,
                            borderColor: "primary.main",
                        }}
                    >
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "column",
                        alignItems: isPhone ? "center" : "end",
                        fontSize: "20px",
                        lineHeight: "22.48px",
                        color: "primary.main",
                    }}
                >
                    {menuRight.map((item) => (
                        <Typography key={item} sx={{ mx: "12px" }}>
                            {item}
                        </Typography>
                    ))}
                    <Box
                        sx={{
                            display: isPhone ? "block" : "none",
                            marginTop: "10px",
                            marginBottom: "10px",
                            width: "60px",
                            borderBottom: 4,
                            borderColor: "primary.main",
                        }}
                    >
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexFlow: "row",
                            alignItems: isPhone ? "center" : "start",
                            fontSize: "20px",
                            lineHeight: "22.48px",
                            color: "primary.main",
                        }}
                    >
                        <img
                            alt="facebook-logo-icon"
                            width="40px"
                            height="40px"
                            style={{margin: "6px"}}
                            src={require("../../assets/icons/facebook-logo.png")}
                        />
                        <img
                            alt="twitter-logo-icon"
                            width="40px"
                            height="40px"
                            style={{margin: "6px"}}
                            src={require("../../assets/icons/twitter-logo.png")}
                        />
                        <img
                            alt="linkedin-logo-icon"
                            width="40px"
                            height="40px"
                            style={{margin: "6px"}}
                            src={require("../../assets/icons/linkedin-logo.png")}
                        />
                        <img
                            alt="youtube-logo-icon"
                            width="40px"
                            height="40px"
                            style={{margin: "6px"}}
                            src={require("../../assets/icons/youtube-logo.png")}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 50,
                    width: "100%",
                    fontSize: "20px",
                    lineHeight: "22.48px",
                    color: "white",
                    backgroundColor: "primary.main",
                }}
            >
                <Typography>
                    Copyright Ⓒ 2022 Esthetlix. All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;
