import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/elements/mainContainer";
import FormHeader from "../../components/elements/formHeader";
import FormFooter from "../../components/elements/formFooter";
import ContinueButton from "../../components/elements/continueButton";
import BackButton from "../../components/elements/backButton";
import Input from "../../components/elements/input";
import InputFirstAndLastName from "../../components/elements/inputTwoFields";
import Dropdown from "../../components/elements/dropdown";
import PhoneInputComponent from "../../components/elements/phoneInput";
import FormCheckbox from "../../components/elements/checkbox";
import { preferedContact } from "../../constants";

export default function PatientDetails() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();

  const handleClickContinue = () => {
    navigate("/appointment-billing");
  }

  const handleClickBack = () => {
    navigate("/appointment-schedule");
  }

  return (
    <MainContainer>
      <FormHeader label={"Schedule Your Appointment"}/>
      <Box 
        sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: isPhone ? "60px" : "70px",
          backgroundColor: "white",
          border: 1,
          borderColor: "primary.main",
          px: isPhone ? "25px" : "45px",
          mb: "1px"
        }}
      >
        <Typography variant={isPhone ? "p" : "h5"} color="primary.main">
          2 - Patient details
        </Typography>
      </Box>
      <InputFirstAndLastName label="Patient"/>
      <Input label="E-mail address"/>
      <Input label="Confirm e-mail"/>
      <PhoneInputComponent label="Phone number"/>
      <Dropdown label="Prefered follow-up via" options={preferedContact}/>
      <FormCheckbox label="Join us! Our members can access savings up to 50%"/>
      <Input label="Promotion code"/>
      <FormFooter>
        <BackButton handleClick={handleClickBack} />
        <ContinueButton handleClick={handleClickContinue} />
      </FormFooter>
    </MainContainer>
  );
}
