import React from "react";

import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export default function InputBox({ children, label, ...props }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        justifyContent: "space-between",
        mb: "1px",
        alignItems: "center",
        height: isPhone ? "60px" : "70px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"}>
        {label}
      </Typography>
      {children}
    </Box>
  );
}
