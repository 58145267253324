import React from "react";

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
      borderRadius: "50px",
      padding: "10px 20px",
      height: "30px",
    },
    "& .MuiFilledInput-input": {
      padding: "0",
    },
  },
  dropdown: {
    "& .flag-dropdown": {
      borderRadius: "20px 0px 0px 20px !important",
    },
  },
}));

export default function PhoneInputComponent({ id, name = "phone", value, onChange, label, country = "rs" }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        justifyContent: "space-between",
        mb: "1px",
        alignItems: "center",
        height: isPhone ? "60px" : "70px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"} sx={{}}>
        {label}
      </Typography>
      <PhoneInput
        style={{ width: isPhone ? "60%" : "70%" }}
        dropdownStyle={{
          border: "none",
        }}
        value={value}
        country={country}
        onChange={(phone) => onChange(name, phone)}
        name={name}
        inputStyle={{
          width: "100%",
          height: "43px",
          border: "none",
          outline: "none",
          borderRadius: "50px",
          fontFamily: "Montserrat-SemiBold",
        }}
      />
    </Box>
  );
}
