import { Box, Typography, useMediaQuery, useTheme, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import ClinicCardLight from "../components/cards/ClinicCardLight";
import SearchBox from "../components/common/SearchBox/SearchBox";
import CircularProgress from "@mui/material/CircularProgress";
import { Container } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchClinics, fetchClinic, fetchFilters } from "../redux/reducers/clinicSlice";
import { setSelectedCity, setSelectedCountry } from "../redux/reducers/countrySlice";
import { setSelectedTreatment } from "../redux/reducers/treatmentSlice";

export default function ClinicsListPage() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      fetchClinics({
        treatment_type_id: location.state.treatment_type_id,
        country_id: location.state.country_id,
        city: location.state.city,
      })
    );
    dispatch(setSelectedCity(location.state.city));
    dispatch(setSelectedCountry(location.state.country_id));
    dispatch(setSelectedTreatment(location.state.treatment_type_id));
    dispatch(
      fetchFilters({
        treatment_type_id: location.state.treatment_type_id,
        country_id: location.state.country_id,
        city: location.state.city,
      })
    );
  }, [dispatch, location]);

  const clinics = useSelector((state) => state.clinics.clinicsList);
  const fetchingClinics = useSelector((state) => state.clinics.fetchingClinics);
  const sendData = (data) => {
    dispatch(fetchClinic({ clinic_id: data }));
    navigate("/clinic-details", { state: { clinic_id: data } });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
      <Box
        sx={{
          height: isPhone ? "420px" : "150px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: isPhone ? "" : "center",
          px: isPhone ? "" : "20px",
        }}
      >
        {fetchingClinics ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="h4" color="primary.main" sx={{ marginBottom: "5px" }}>
            {clinics.length} {clinics.length === 1 ? "office " : "offices"} found
          </Typography>
        )}
        <Typography variant="h6" color="primary.main" sx={{ marginBottom: "5px" }}>
          Sort by
          <IconButton
            color="primary"
            sx={{
              alignSelf: isPhone ? "center" : "start",
              m: isPhone ? 0 : 0.2,
            }}
            // onClick={handleClick}
          >
            <img
              alt="dropdown-icon"
              style={{
                width: "20px",
                height: "11.36px",
              }}
              src={require("../assets/icons/arrow-down.png")}
            />
          </IconButton>
        </Typography>
      </Box>
      <Container
        sx={{
          width: "100%",
          display: isPhone ? "" : "flex",
          justifyContent: isPhone ? "" : "center",
          position: "absolute",
          top: isPhone ? "50px" : "100px",
          left: "0",
          right: "0",
        }}
      >
        <SearchBox />
      </Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          minHeight: "370px",
          backgroundColor: "secondary.light",
          paddingTop: "80px",
        }}
      >
        {fetchingClinics && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!fetchingClinics &&
          clinics.length > 0 &&
          clinics.map((clinic) => {
            return <ClinicCardLight sendData={sendData} key={clinic.id} clinic={clinic} />;
          })}
      </Box>
    </Box>
  );
}
