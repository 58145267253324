export const menu = ["Aesthetic services", "Dental services", "Special offers", "Perfect destinatinos"];
export const menuFooter = ["Privacy Policy", "Tearms of Service", "FAQs"];

export const preferedContact = [
  { id: 1, value: "E-mail" },
  { id: 2, value: "Phone" },
];

export const appointmentTimes = [
  { id: 1, value: "Anytime" },
  { id: 2, value: "Morning" },
  { id: 3, value: "Afternoon" },
  { id: 4, value: "Evening" },
];

export const retisterClinicStatusMessages = {
  pending:
    "Thank you for registration. We have received your application. You will receive an email after aour admin will approve your registration",
};
