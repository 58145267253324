import React from "react";
import { Button } from "@mui/material";

export default function BackButton({ handleClick }) {
  return (
    <Button
      onClick={handleClick}
      sx={{
        width: "147px",
        height: "45px",
        borderRadius: 4,
        backgroundColor: "white",
        color: "primary.main",
      }}
      variant="contained"
    >
      Back
    </Button>
  );
}
