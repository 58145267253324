import React, { useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export default function ClinicCardDark({ clinic }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();
  const [certificateMenuOpen, setCertificateMenuOpen] = useState(false);
  const [additionalServiceMenuOpen, setAdditionalServiceMenuOpen] = useState(false);
  
  const onClickDoctorsList = () => {
    navigate("/doctors", { state: { clinic_id: clinic.clinic_details[0].id }});
  }

  const onClickBookAppointment = () => {
    navigate("/appointment-schedule", { state: { clinic_id: clinic.clinic_details[0].id }});
  }

  const onClick = () => {
    // navigate("/doctors", { state: { clinic: clinic }});
  }

  const onClickSeeMoreCertificates = () => {
    if (!certificateMenuOpen) {
      document.getElementById('certificate_box').style.height = "inherit";
      document.getElementById('certificate_text').innerText = "See less";
      document.getElementById('certificate_img').style.transform = "rotate(180deg)";
      setCertificateMenuOpen(true);
    } else {
      document.getElementById('certificate_box').style.height = "90px";
      document.getElementById('certificate_text').innerText = "See more";
      document.getElementById('certificate_img').style.transform = "rotate(0deg)";
      setCertificateMenuOpen(false);
    }
  }

  const onClickSeeMoreAdditionalServices = () => {
    if (!additionalServiceMenuOpen) {
      document.getElementById('additional_service_box').style.height = "inherit";
      document.getElementById('additional_service_text').innerText = "See less";
      document.getElementById('additional_service_img').style.transform = "rotate(180deg)";
      setAdditionalServiceMenuOpen(true);
    } else {
      document.getElementById('additional_service_box').style.height = "90px";
      document.getElementById('additional_service_text').innerText = "See more";
      document.getElementById('additional_service_img').style.transform = "rotate(0deg)";
      setAdditionalServiceMenuOpen(false);
    }
  }

  return (
    <Card 
      sx={{ 
        maxWidth: 350, 
        width: 350,
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",  
        borderRadius: "15px",
        backgroundColor: "primary.main", 
        }}
    >
      <CardMedia
        height="240"
        component="img"
        alt="clinic-image"
        image="/clinic.jpg"
        sx={{
          width: "100%",
          maxHeight: "300px",
        }}
      />

      <CardContent 
        sx={{ 
          padding: "20px", 
          display: "flex", 
          flexDirection: "column",
          justifyContent: isPhone ? "center" : "flex-start"
        }}
      >
        <Box
          sx={{ 
            display: "flex", 
            flexDirection: isPhone ? "column" : "row",
            justifyContent: isPhone ? "center" : "flex-start"
          }}
        >
          <Typography variant="h6" color="white" sx={{ marginBottom: "5px" }}>
            zvezice 
          </Typography>
          <Typography variant="h6" color="white" sx={{ marginBottom: "5px" }}>
            8.5/23 reviews
          </Typography>
        </Box>
        <Typography variant="h6" color="white" sx={{ marginBottom: "5px" }}>
          {clinic.clinic_details[0].name}
        </Typography>
        <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
          {clinic.clinic_details[0].address}, {clinic.clinic_details[0].city}
        </Typography>
        <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
          {clinic.clinic_details[0].email}
        </Typography>
        <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
          {clinic.clinic_details[0].phone_number}
        </Typography>
        <Typography variant="h6" color="white" sx={{ marginBottom: "5px" }}>
          Working hours:
        </Typography>
        <Box>
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px", marginRight: "5px" }}>
            Mon - Fri: 
          </Typography>
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
            {clinic.clinic_details[0].working_hours_from} - {clinic.clinic_details[0].working_hours_to}
          </Typography>
        </Box>
        <Box
          sx={{mb: "20px"}}
        >
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px", marginRight: "5px" }}>
            Saturday: 
          </Typography>
          <Typography variant="body3" color="white" sx={{ marginBottom: "5px" }}>
            {clinic.clinic_details[0].working_hours_from} - {clinic.clinic_details[0].working_hours_to}
            {/* {clinic.clinic_details[0].working_hours_from_sat} - {clinic.clinic_details[0].working_hours_to_sat} */}
          </Typography>
        </Box>
        <Button
          onClick={onClickDoctorsList} 
          sx={{
            width: "100%",
            height: "80px",
            borderRadius: 6,
            borderColor: "white",
            borderWidth: "3px",
            color: "white",
            ":hover": {
              bgcolor: "secondary.light",
              color: "primary.main",
            },
          }} 
          variant="outlined" 
        >
          See the list of doctors
        </Button>
        <Box
          sx={{ 
            px: "20px",
            py: "5px",
            backgroundColor: "white",
            borderRadius: 6,
            marginTop: "10px",
            marginBottom: "10px"
          }}
        >
          <Box
            sx={{ 
              mb: "10px",
            }}
          >
            <Box
              id="certificate_box"
              sx={{
                height: clinic.certificates?.length > 2 ? "90px" : "inherit",
                overflow: clinic.certificates?.length > 2 ? "hidden" : "normal",
              }} 
            >
              <Typography variant="h6" color="primary.main">
                Certificates
              </Typography>
              {clinic.certificates?.map((c, key) => (
                <Box
                  key={key} 
                  sx={{ 
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                      alt="cash-icon"
                      height="15px"
                      style={{margin: "6px"}}
                      src={require("../../assets/icons/checked-box.png")}
                  />
                  <Link to={c.document_url}>
                    <Typography 
                      variant="body3"
                      color="primary.main"
                      sx={{ fontSize: "1.1rem"}}
                    >
                      {c.document_name}
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Box>
            {clinic.certificates?.length > 2 && (
              <Box
                onClick={onClickSeeMoreCertificates}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography
                  id="certificate_text"
                  sx={{
                    textAlign: "left",
                    fontWeight: 400,
                    fontSize: "1rem",
                    fontFamily: "Montserrat-Regular",
                    color: "primary.main",
                  }}
                >
                  See more
                </Typography>
                <Box
                  id="certificate_img"
                  sx={{
                    padding: "0 10px",
                  }}
                >
                  <img
                    alt="dropdown-icon"
                    style={{
                      width: "20px",
                      height: "10.96px",
                    }}
                    src={require("../../assets/icons/arrow-down.png")}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{ 
              mb: "10px",
            }}
          >
            <Box
              id="additional_service_box"
              sx={{
                height: clinic.additional_services?.length > 2 ? "90px" : "inherit",
                overflow: clinic.additional_services?.length > 2 ? "hidden" : "normal",
              }} 
            >
              <Typography variant="h6" color="primary.main">
                Additional services
              </Typography>
              {clinic.additional_services?.map((as, key) => (
                <Box
                  key={key} 
                  sx={{ 
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                      alt="cash-icon"
                      height="15px"
                      style={{margin: "6px"}}
                      src={require("../../assets/icons/checked-box.png")}
                  />
                  <Typography 
                    variant="body3"
                    color="primary.main"
                    sx={{ fontSize: "1.1rem"}}
                  >
                    {as.additional_service_name}
                  </Typography>
                </Box>
              ))}
            </Box>
            {clinic.additional_services?.length > 2 && (
              <Box
                onClick={onClickSeeMoreAdditionalServices}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography
                  id="additional_service_text"
                  sx={{
                    textAlign: "left",
                    fontWeight: 400,
                    fontSize: "1rem",
                    fontFamily: "Montserrat-Regular",
                    color: "primary.main",
                  }}
                >
                  See more
                </Typography>
                <Box
                  id="additional_service_img"
                  sx={{
                    padding: "0 10px",
                  }}
                >
                  <img
                    alt="dropdown-icon"
                    style={{
                      width: "20px",
                      height: "10.96px",
                    }}
                    src={require("../../assets/icons/arrow-down.png")}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {(clinic.clinic_details[0].has_card_payment_method ||
          clinic.clinic_details[0].has_cash_payment_method ||
          clinic.clinic_details[0].has_bank_transfer_payment_method
          ) ?
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="white" sx={{ marginBottom: "5px" }}>
              Payment methods
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {clinic.clinic_details[0].has_card_payment_method ?
                <img
                  alt="master-card-icon"
                  height="28px"
                  style={{margin: "6px", padding:"0px 10px"}}
                  src={require("../../assets/payments/master-card.png")}
                /> : null
              }
              {clinic.clinic_details[0].has_cash_payment_method ?
                <img
                  alt="cash-icon"
                  height="15px"
                  style={{margin: "6px", padding:"0px 10px"}}
                  src={require("../../assets/payments/cash.png")}
                /> : null
              }
              {clinic.clinic_details[0].has_bank_transfer_payment_method ?
                <img
                  alt="bank-transfer-icon"
                  height="15px"
                  style={{margin: "6px", padding:"0px 10px"}}
                  src={require("../../assets/payments/bank-transfer.png")}
                /> : null
              }
            </Box>
          </Box> 
          :
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "10px" }}>
            <Typography variant="h6" color="white">
              No payment methods
            </Typography>
            <Typography variant="h6" color="white">
              currently listed
            </Typography>
          </Box>
          
        }
        <Button
          onClick={onClickBookAppointment} 
          sx={{
            width: "100%",
            height: "80px",
            borderRadius: 6,
            backgroundColor: "secondary.light",
            color: "primary.main",
            marginBottom: "10px",
            ':hover': {
              bgcolor: 'white',
            },
          }} 
          variant="outlined" 
        >
          Book appointment
        </Button>
        <Button
          onClick={onClick} 
          sx={{
            width: "100%",
            height: "80px",
            borderRadius: 6,
            borderColor: "white",
            borderWidth: "3px",
            color: "white",
            marginBottom: "10px",
            ":hover": {
              bgcolor: "secondary.light",
              color: "primary.main",
            },
          }} 
          variant="outlined" 
        >
          Contact clinic
        </Button>
      </CardContent>
    </Card>
  );
}
