import React, { useEffect } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import Desktop from "./Variants/Desktop";
import Mobile from "./Variants/Mobile";
import { useSelector, useDispatch } from "react-redux";
import { fetchClinics, fetchFilters } from "../../../redux/reducers/clinicSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { setSelectedCountry, setSelectedCity } from "../../../redux/reducers/countrySlice";
import { setSelectedTreatment } from "../../../redux/reducers/treatmentSlice";

const SearchBox = () => {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const selectedTreatmentTypeId = useSelector((state) => state.categories.selectedTreatmentTypeId);
  const selectedCountryId = useSelector((state) => state.countries.selectedCountryId);
  const selectedCity = useSelector((state) => state.countries.selectedCity);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    dispatch(fetchFilters({ treatment_type_id: 0, country_id: 0, city: "All" }));
  }, [dispatch]);

  useEffect(() => {        
    dispatch(setSelectedCountry(0));
    dispatch(setSelectedTreatment(0));
    dispatch(setSelectedCity("All"));
  }, [location, dispatch])

  const handleClick = () => {
    dispatch(
      fetchClinics({ treatment_type_id: selectedTreatmentTypeId, country_id: selectedCountryId, city: selectedCity })
    );
    if (path !== "/clinics") {
      navigate("/clinics", { state: { treatment_type_id: selectedTreatmentTypeId, country_id: selectedCountryId, city: selectedCity }});
    }
  };

  if (isBelowMd) {
    return <Mobile isBelowMd={isBelowMd} handleClick={handleClick} />;
  }

  return <Desktop isBelowMd={isBelowMd} handleClick={handleClick} />;
};

export default SearchBox;
