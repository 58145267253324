import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useMediaQuery, useTheme, } from "@mui/material";

export default function ClinicCardLight({ clinic, sendData }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const onClick = () => {
    sendData(clinic.id)
  }

  return (
    <Card 
      sx={{ 
        maxWidth: isPhone ? "90%" : 1100, 
        display: "flex",
        flexDirection: isPhone ? "column" : "row",
        alignItems: isPhone ? "center" : "normal",
        marginBottom: "20px", 
        mx: "24px",
        padding: isPhone ? "0px 12px" : "25px", 
        borderRadius: "15px" 
        }}
    >
      <CardMedia
        height="300"
        component="img"
        alt="clinic-image"
        image="/clinic.jpg"
        sx={{
          width: isPhone ? "100%" : "50%",
          maxWidth: isPhone ? "90%" : "300px",
          borderRadius: "7px",
          maxHeight: "300px",
          marginTop: isPhone ? "20px" : "0px",
          marginRight: isPhone ? "1px" : "20px",
        }}
      />

      <CardContent sx={{ padding: 0, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" color="primary.main" sx={{ marginBottom: "5px" }}>
          {clinic.name}
        </Typography>
        <Typography
          variant="body3"
          color="secondary.main"
          sx={{ position: "relative", paddingLeft: "20px", marginBottom: "5px" }}
        >
          <LocationOnIcon sx={{ fontSize: "16px", position: "absolute", left: 0, top: 3 }} />
          {clinic.city}
        </Typography>
        <Typography variant="body3" color="primary.main" sx={{ marginBottom: "5px" }}>
          {clinic.description}
        </Typography>
        <Box sx={{ 
          width: "100%", 
          display: "flex",
          justifyContent: "space-between", 
          marginTop: "50px" 
        }}>
          {clinic.price && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
                borderTop: 4,
                borderColor: "secondary.light",
              }}
            >
              <Typography variant="body3" color="primary.main">
                Treatment price:
              </Typography>
              <Typography variant="h5" color="primary.main">
                {clinic.price} {clinic.currency}
              </Typography>
            </Box>
          )}
          <Button 
            onClick={onClick} 
            sx={{
              ml: "auto",
              mr: "16px",
              my: "6px",
              width: "147px",
              height: "35px",
              borderRadius: 20,
            }} 
            variant="contained" 
            id={clinic.id}
          >
            See more
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
