import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes></Routes>
        </BrowserRouter>
    );
};

export default Router;
