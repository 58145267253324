import React from "react";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";

export default function TreatmentList({ treatments }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box 
      sx={{ 
        display: "flex",
        flexDirection: "column",
        marginTop: "80px",
        marginBottom: "20px",  
      }}
    >
      <Box 
        sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
          backgroundColor: "secondary.light",
          px: "20px",
          borderRadius: "20px 20px 0px 0px",
        }}
      >
        <Typography variant="h6" color="primary.main" sx={{ width: "60%" }}>
          Treatment
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ width: isPhone ? "auto" : "20%", display: "flex", justifyContent: "flex-end" }}>
          Price
        </Typography>
        <Typography variant="h6" color="primary.main" sx={{ width: isPhone ? "auto" : "20%", display: "flex", justifyContent: "flex-end" }}>
          Duration
        </Typography>
      </Box>

      {treatments.map((t, key) => (
          <Box
            key={key}
          >
            <Box 
              sx={{ 
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: isPhone ? "100px" : "70px",
                backgroundColor: "primary.main",
                overflow: "hidden",
                px: "20px",
                mb: "1px"
              }}
            >
              <Typography variant="h6" color="white">
                {t.category}
              </Typography>
            </Box>
            {t?.treatment_type.map((tt, key) => (
                <Box 
                  key={key}
                  sx={{ 
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: isPhone ? "100px" : "70px",
                    backgroundColor: "primary.light",
                    px: "20px",
                    mb: "1px"
                  }}
                >
                  <Typography 
                    variant={isPhone ? "subtitle1" : "h6"}
                    color="primary.main"
                    sx={{ 
                      width: "60%",
                    }}
                  >
                    {tt.treatment_type_name}
                  </Typography>
                  <Box 
                    sx={{ 
                      width: "20%", 
                      display: "flex", 
                      flexDirection: isPhone ? "column" : "row",
                      justifyContent: isPhone ? "center" : "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      color="primary.main" 
                      sx={{ 
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis", 
                        mr: isPhone ? "0px" : "5px"
                      }}
                    >
                      {tt.treatment_price} 
                    </Typography>
                    <Typography 
                      variant="h6" 
                      color="primary.main" 
                      sx={{ 
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis", 
                      }}
                    >
                      {tt.treatment_currency}
                    </Typography>
                  </Box>
                  <Box 
                    sx={{ 
                      width: "20%", 
                      display: "flex", 
                      flexDirection: isPhone ? "column" : "row",
                      justifyContent: isPhone ? "center" : "flex-end",
                      alignItems: "center"
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      color="primary.main" 
                      sx={{ 
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis", 
                        mr: isPhone ? "0px" : "5px"
                      }}
                    >
                      {tt.treatment_duration} 
                    </Typography>
                    <Typography 
                      variant="h6" 
                      color="primary.main" 
                      sx={{ 
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis", 
                      }}
                    >
                      {tt.treatment_duration === "1" ? "day" : "days"}
                    </Typography>
                  </Box>
                </Box>
            ))}
          </Box>
        ))
      }

      <Box 
        sx={{ 
          height: "70px",
          backgroundColor: "secondary.light",
          borderRadius: "0px 0px 20px 20px",
        }}
      >

      </Box>
    </Box>
  );
}
