import React from "react";

import { Button } from "@mui/material";
import SelectTreatmentType from "../Items/SelectTreatmentType";
import SelectCountry from "../Items/SelectCountry";
import SelectCity from "../Items/SelectCity";

import SearchIcon from "@mui/icons-material/Search";

const Mobile = ({ isBelowMd, handleClick }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
            <SelectTreatmentType
                sx={{
                    my: 1,
                    mx: 4,
                    borderRadius: "20px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    color: "primary.main",
                    background: "white",
                }}
                isBelowMd={isBelowMd}
            ></SelectTreatmentType>

            <SelectCountry
                sx={{
                    my: 1,
                    mx: 4,
                    borderRadius: "20px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    color: "primary.main",
                    background: "white",
                }}
                isBelowMd={isBelowMd}
            ></SelectCountry>

            <SelectCity
                sx={{
                    my: 1,
                    mx: 4,
                    borderRadius: "20px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    color: "primary.main",
                    background: "white",
                }}
                isBelowMd={isBelowMd}
            ></SelectCity>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ flexGrow: 1 }}
                    startIcon={<SearchIcon />}
                    onClick={handleClick}
                    sx={{
                        my: 1,
                        mx: 4,
                        height: "81.5px",
                        borderRadius: "20px",
                    }}
                >
                    Search
                </Button>
            </div>
        </div>
    );
};

export default Mobile;
