import React from "react";

import { styled, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const CustomTextField = styled(TextField)(() => ({
    "& fieldset": {
        borderRadius: "30px",
    },
}));

const SearchBar = () => {
    return (
        <div
            style={{
                // borderRadius: "20px",
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
                // marginTop: "50px",
                minWidth: "100%",
                minHeight: "25%",
                border: "1px solid rgba(0, 0, 0, 0.25)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                paddingBottom: "10px",
            }}
        >
            <CustomTextField
                color="primary"
                variant="outlined"
                size="small"
                label="Search"
                sx={{ width: "9.5rem" }}
            ></CustomTextField>
            <SearchIcon fontSize="large" color="primary" sx={{ mx: 1 }} />
        </div>
    );
};

export default SearchBar;
