import { ThemeProvider } from "@mui/system";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Sidebar from "./components/common/Sidebar/Sidebar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AdapterMoment from "@date-io/moment";
import theme from "./themes/theme";
import ScrollToTop from "./scrollToTop";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getLoggedInUser, setIsloading } from "./redux/reducers/userSlice";

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsloading());
    dispatch(getLoggedInUser());
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Header openSidebar={() => setShowSidebar(!showSidebar)} />
        <Sidebar anchor="right" status={showSidebar} toggleSidebar={(state) => setShowSidebar(state)} />
        <Outlet />
        <Footer />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
