import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Input from "../elements/input";
import PhoneInputComponent from "../elements/phoneInput";
import FormFooter from "../elements/formFooter";
import ContinueButton from "../elements/continueButton";
import { useNavigate } from "react-router";
import FormHeader from "../elements/formHeader";
import Dropdown from "../elements/dropdown";
import { fetchCountries } from "../../redux/reducers/countrySlice";
import { Typography, FormControlLabel, Checkbox, Box } from "@mui/material";
import { Circle } from "@mui/icons-material";

import { useMediaQuery, useTheme } from "@mui/material";
import InputBox from "../elements/inputBox";
import FileInput from "../elements/fileInput";
import CustomInput from "../elements/customInput";
import { registerClinic } from "../../redux/reducers/registerClinicSlice";
import { setUser } from "../../redux/reducers/userSlice";

export default function RegisterClinicForm() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.countries.countriesList);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      repeat_password: "",
      name: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
      phoneNumber: "",
      fax: "",
      aestheticServices: false,
      dentalServices: false,
      licenseNumber: "",
      responsiblePerson: "",
      file: "",
    },
    onSubmit: (values) => {
      const {
        email,
        password,
        repeat_password,
        name,
        address,
        city,
        country,
        postalCode,
        phone,
        fax,
        aestheticServices,
        dentalServices,
        licenseNumber,
        responsiblePerson,
        file,
      } = values;
      const objectForm = {
        email,
        password,
        name,
        address,
        city,
        country_id: country,
        phone_number: phone,
        aesthetic_services: aestheticServices,
        dental_services: dentalServices,
      };
      dispatch(registerClinic(objectForm))
        .unwrap()
        .then((res) => dispatch(setUser(res)));
    },
  });

  const onHandleClick = () => {
    navigate("/");
  };
  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormHeader label={"Register Clinic Form"} />
        <Input label="Email address" name={"email"} onChange={formik.handleChange} value={formik.values.email} />
        <Input
          label="Password"
          type="password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <Input
          label="Repeat Password"
          type="password"
          name="repeat_password"
          onChange={formik.handleChange}
          value={formik.values.repeat_password}
        />

        <Input label="Name of Clinic" name={"name"} onChange={formik.handleChange} value={formik.values.name} />
        <Input label="Address" name={"address"} onChange={formik.handleChange} value={formik.values.address} />
        <Input label="City" name={"city"} onChange={formik.handleChange} value={formik.values.city} />
        <Dropdown
          label={"Country"}
          name="country"
          options={countries}
          onChange={formik.handleChange}
          value={formik.values.country}
        />

        <Input
          label="Postal Code"
          name={"postalCode"}
          onChange={formik.handleChange}
          value={formik.values.postalCode}
        />
        <PhoneInputComponent label="Phone Number" onChange={formik.setFieldValue} value={formik.values.phoneNumber} />
        <Input label="Fax" name={"fax"} onChange={formik.handleChange} value={formik.values.fax} />

        <Box
          bgcolor="primary.light"
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            height: isPhone ? "60px" : "70px",
            px: "30px",
            marginBottom: "1px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="primary.main" variant={isPhone ? "p" : "h6"} sx={{ marginRight: "10px" }}>
              Aesthetic Services
            </Typography>
            <FormControlLabel
              sx={{
                mr: isPhone ? "5px" : "12px",
              }}
              control={
                <Checkbox
                  name="aestheticServices"
                  onChange={formik.handleChange}
                  icon={<span className="radio-filled"></span>}
                  checkedIcon={<Circle color="primary.main" />}
                />
              }
            />
          </Box>
          <Typography color="primary.main">OR</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="primary.main" variant={isPhone ? "p" : "h6"} sx={{ marginRight: "10px" }}>
              Dental services
            </Typography>
            <FormControlLabel
              sx={{
                mr: isPhone ? "5px" : "12px",
              }}
              control={
                <Checkbox
                  name="dentalServices"
                  onChange={formik.handleChange}
                  icon={<span className="radio-filled"></span>}
                  checkedIcon={<Circle color="primary.main" />}
                />
              }
            />
          </Box>
        </Box>
        <InputBox label="License Number ">
          <Box sx={{ width: "70%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <CustomInput desktopWidth="50%" />
            <FileInput onChange={formik.setFieldValue} value={formik.values.file} />
          </Box>
        </InputBox>
        <Input
          label="Responsible Person"
          name={"postalCode"}
          onChange={formik.handleChange}
          value={formik.values.postalCode}
        />
        <FormFooter justify="flex-end">
          <ContinueButton />
        </FormFooter>
      </form>
    </>
  );
}
