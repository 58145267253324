import React, { useState } from "react";

import { Box, IconButton } from "@mui/material";
import { Menu, MenuItem } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import { setSelectedTreatment, setSelectedTreatmentDisplay, setIsTreatmentSelected } from "../../../../redux/reducers/treatmentSlice";
import { fetchFilters } from "../../../../redux/reducers/clinicSlice";
import NestedMenuItem from "material-ui-nested-menu-item";
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

const SelectTreatmentType = (props) => {
    const selectedTreatmentTypeDisplay = useSelector((state) => state.categories.selectedTreatmentTypeDisplay );
    const isTreatmentSelected = useSelector((state) => state.categories.isTreatmentSelected );
    const filters = useSelector((state) => { if (state.clinics.fetchingFilters) { return null } else {return state.clinics.filters } });
    const isLoading = useSelector((state) => state.clinics.fetchingFilters );
    const countryId = useSelector((state) => state.countries.selectedCountryId);
    const city = useSelector((state) => state.countries.selectedCity);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const itemHandleClose = (event) => {
        setAnchorEl(null);
        dispatch(setIsTreatmentSelected(true));
        event.preventDefault();
        dispatch(setSelectedTreatmentDisplay(event.target.innerText));
        dispatch(setSelectedTreatment(event.target.value));
        dispatch(fetchFilters({ treatment_type_id: event.target.value, country_id: countryId, city: city }));
    };
    const handleCancelClick = () => {
        dispatch(setIsTreatmentSelected(false));
        dispatch(setSelectedTreatmentDisplay("Select a Treatment"));
        dispatch(setSelectedTreatment(0));
        dispatch(fetchFilters({ treatment_type_id: 0, country_id: countryId, city: city }));
    };

    return (
        <Box
            sx={{
                display: "flex",
                fontFamily: "Montserrat-Regular",
                flexGrow: 1,
                px: 2,
                py: 1.5,
                ...props.sx,
            }}
        >
            <Box sx={{ flexGrow: 1, minWidth: "0" }}>
                <Box style={{ fontSize: "1.1rem", margin: "6px 0px 10px" }}>Treatment</Box>
                <Box 
                    style={{ 
                        fontSize: "1.5rem",
                        width: "calc(80%)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >{selectedTreatmentTypeDisplay}</Box>
            </Box>
            <Box 
                style={{
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column" 
                }}
            >
                <IconButton
                    color="primary"
                    sx={{
                        alignSelf: props.isBelowMd ? "center" : "start",
                        m: props.isBelowMd ? 0 : 0.2,
                    }}
                    onClick={handleClick}
                >
                    <img
                        alt="dropdown-icon"
                        style={{
                            width: "20px",
                            height: "11.36px",
                        }}
                        src={require("../../../../assets/icons/arrow-down.png")}
                    />
                </IconButton>
                {isTreatmentSelected &&
                    <IconButton
                        sx={{
                            alignSelf: props.isBelowMd ? "center" : "end",
                            m: props.isBelowMd ? 0 : 0.2,
                        }}
                        onClick={handleCancelClick}
                    >
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                }
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                classes={{
                    paper: 'MuiCustom-menu'
                }}
            > 
                {isLoading 
                    ?   <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
                    :   filters?.categories_with_types.map((c) => (
                            <NestedMenuItem 
                                parentMenuOpen={open} 
                                key={c.category} 
                                label={c.category}
                            >
                                {c?.treatment_type.map((t) => (
                                    <MenuItem 
                                        onClick={itemHandleClose} 
                                        key={t.treatment_type_name} 
                                        value={t.treatment_type_id}
                                    >
                                        {t.treatment_type_name}
                                        <br />
                                    </MenuItem>
                                ))}
                            </NestedMenuItem>
                        ))
                }

            </Menu>
        </Box>
    );
};

export default SelectTreatmentType;
