import React from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { CircleOutlined, Circle } from '@mui/icons-material';
import { useMediaQuery, useTheme, } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-input": {
      padding: "0",
    },
  },
}));

export default function Input({ id, name, value, onChange, label, options }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <Box
      bgcolor="primary.light"
      sx={{
        display: "flex",
        px: isPhone ? "25px" : "45px",
        py: "15px",
        justifyContent: "space-between",
        alignItems: "flex-start",
        mb: "1px",
        height: "162px",
      }}
    >
      <Typography color="primary.main" variant={isPhone ? "p" : "h6"} sx={{}}>
        {label}
      </Typography>
      <Box
        className={classes.root}
        sx={{
          backgroundColor: "white",
          borderRadius: "18px",
          border: 0,
          padding: "0px 0px",
          width: isPhone ? "60%" : "70%",
          height: "162px",
          overflowY: "scroll"
        }}
        //onChange={handleChange}
      >
        {options.map((o) => {
          return (
            <Box key={o.id} >
              <FormControlLabel 
                control={
                  <Checkbox icon={<CircleOutlined color="primary.main" />} checkedIcon={<Circle color="primary.main" />} />
                } 
                label={o.value} 
                sx={{ px: "20px" }}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  );
}
