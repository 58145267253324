import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import backendUrl from "../../config";

export const fetchCountries = createAsyncThunk("fetchCountriesResults", async () => {
  const res = await fetch(`${backendUrl}/countries`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch countries");
  }
});

export const fetchCities = createAsyncThunk("fetchCitiesResults", async () => {
  const res = await fetch(`${backendUrl}/cities`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch cities");
  }
});

export const fetchCitiesByCountryId = createAsyncThunk("fetchCitiesByCountryIdResults", async (payload) => {
  const res = await fetch(`${backendUrl}/cities/country_id/${payload.countryId}`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch cities in selected country");
  }
});

export const fetchCitiesByTreatmentId = createAsyncThunk("fetchCitiesByTreatmentIdResults", async (payload) => {
  const res = await fetch(`${backendUrl}/cities/treatment_type_id/${payload.treatmentId}`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch cities in selected country");
  }
});

export const fetchCountriesByTreatmentId = createAsyncThunk("fetchCountriesByTreatmentIdResults", async (payload) => {
  const res = await fetch(`${backendUrl}/countries/treatment_type_id/${payload.treatmentId}`);

  const data = await res.json();

  if (res.ok) {
    return data;
  } else {
    throw new Error("Failed to fetch cities in selected country");
  }
});

const countrySlice = createSlice({
  name: "countries",
  initialState: {
    countriesList: [],
    selectedCountryId: 0,
    selectedCountryDisplay: "Select a Country",
    isCountrySelected: false,
    fetchingCountries: false,
    cities: [],
    selectedCity: "All",
    selectedCityDisplay: "Select a City",
    isCitySelected: false,
    fetchingCities: false,
  },
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
    getCountries: (state, action) => {
      let countries = action.payload;
      state.push(countries);
    },
    setSelectedCountry: (state, action) => {
      const selectedCountryId = action.payload;
      state.selectedCountryId = selectedCountryId;
    },
    setSelectedCity: (state, action) => {
      const selectedCity = action.payload;
      state.selectedCity = selectedCity;
    },
    setSelectedCountryDisplay: (state, action) => {
      const selectedCountryDisplay = action.payload;
      state.selectedCountryDisplay = selectedCountryDisplay;
    },
    setSelectedCityDisplay: (state, action) => {
      const selectedCityDisplay = action.payload;
      state.selectedCityDisplay = selectedCityDisplay;
    },
    setIsCountrySelected: (state, action) => {
      const isCountrySelected = action.payload;
      state.isCountrySelected = isCountrySelected;
    },
    setIsCitySelected: (state, action) => {
      const isCitySelected = action.payload;
      state.isCitySelected = isCitySelected;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countriesList = action.payload;
      if (state.fetchingCountries) {
        state.fetchingCountries = false;
      }
    });
    builder.addCase(fetchCountries.pending, (state, action) => {
      state.countriesList = {};
      if (!state.fetchingCountries) {
        state.fetchingCountries = true;
      }
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      if (state.fetchingCities) {
        state.fetchingCities = false;
      }
    });
    builder.addCase(fetchCities.pending, (state, action) => {
      state.cities = {};
      if (!state.fetchingCities) {
        state.fetchingCities = true;
      }
    });
    builder.addCase(fetchCitiesByCountryId.fulfilled, (state, action) => {
      state.cities = action.payload;
      if (state.fetchingCities) {
        state.fetchingCities = false;
      }
    });
    builder.addCase(fetchCitiesByCountryId.pending, (state, action) => {
      state.cities = {};
      if (!state.fetchingCities) {
        state.fetchingCities = true;
      }
    });
    builder.addCase(fetchCountriesByTreatmentId.fulfilled, (state, action) => {
      state.countriesList = action.payload;
      if (state.fetchingCountries) {
        state.fetchingCountries = false;
      }
    });
    builder.addCase(fetchCountriesByTreatmentId.pending, (state, action) => {
      state.countriesList = {};
      if (!state.fetchingCountries) {
        state.fetchingCountries = true;
      }
    });
    builder.addCase(fetchCitiesByTreatmentId.fulfilled, (state, action) => {
      state.cities = action.payload;
      if (state.fetchingCities) {
        state.fetchingCities = false;
      }
    });
    builder.addCase(fetchCitiesByTreatmentId.pending, (state, action) => {
      state.cities = {};
      if (!state.fetchingCities) {
        state.fetchingCities = true;
      }
    });
  },
});

export const {
  getCountries,
  setSelectedCountry,
  setSelectedCity,
  setSelectedCountryDisplay,
  setSelectedCityDisplay,
  setIsCountrySelected,
  setIsCitySelected,
} = countrySlice.actions;

export default countrySlice.reducer;
