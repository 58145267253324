import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import MainContainer from "../../components/elements/mainContainer";
import FormHeader from "../../components/elements/formHeader";
import FormFooter from "../../components/elements/formFooter";
import ContinueButton from "../../components/elements/continueButton";
import BackButton from "../../components/elements/backButton";
import SpceialOfferCard from "../../components/cards/SpecialOffer";

export default function AppointmentDetails() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return (
    <MainContainer>
      <Box 
        sx={{ 
          display: "flex",
          flexDirection: isPhone ? "column" : "row",
          alignItems: isPhone ? "center" : "flex-start",
          justifyContent: isPhone ? "unset" : "space-between"
        }}
      >
        <Box
          sx={{
            width: isPhone ? "100%" : "70%"
          }}
        >
          <FormHeader label={"Your Appointment Details"}/>
          <Box
            sx={{
              backgroundColor: "primary.light",
              height: "500px"
            }}
          >
          </Box>
          <FormFooter>
            <BackButton />
            <ContinueButton />
          </FormFooter>
        </Box>
        <Box
          sx={{
            width: "25%"
          }}
        >
          <SpceialOfferCard />
        </Box>
      </Box>
    </MainContainer>
  );
}
