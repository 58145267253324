import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { setSelectedCountry, setSelectedCountryDisplay, setIsCountrySelected } from "../../../../redux/reducers/countrySlice";
import { fetchFilters } from "../../../../redux/reducers/clinicSlice";
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';

const SelectCountry = (props) => {
    const selectedCountryDisplay = useSelector((state) => state.countries.selectedCountryDisplay);
    const isCountrySelected = useSelector((state) => state.countries.isCountrySelected);
    const filters = useSelector((state) => { if (state.clinics.fetchingFilters) { return null } else {return state.clinics.filters } });
    const isLoading = useSelector((state) => state.clinics.fetchingFilters );
    const treatmentId = useSelector((state) => state.categories.selectedTreatmentTypeId);
    const city = useSelector((state) => state.countries.selectedCity);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const itemHandleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        dispatch(setIsCountrySelected(true));
        dispatch(setSelectedCountryDisplay(event.target.innerText));
        dispatch(setSelectedCountry(event.target.value));
        dispatch(fetchFilters({ treatment_type_id: treatmentId, country_id: event.target.value, city: city }));
    };
    const handleCancelClick = () => {
        dispatch(setIsCountrySelected(false));
        dispatch(setSelectedCountryDisplay("Select a Country"));
        dispatch(setSelectedCountry(0));
        dispatch(fetchFilters({ treatment_type_id: treatmentId, country_id: 0, city: city }));
    };
    
    return (
        <Box
            sx={{
                display: "flex",
                fontFamily: "Montserrat-Regular",
                flexGrow: 1,
                px: 2,
                py: 1.5,
                ...props.sx,
            }}
        >
            <Box sx={{ flexGrow: 1, minWidth: "0" }}>
                <Box style={{ fontSize: "1.1rem", margin: "6px 0px 10px" }}>Country</Box>
                <Box 
                    style={{ 
                        fontSize: "1.5rem",
                        width: "calc(90%)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >{selectedCountryDisplay}</Box>
            </Box>
            <Box 
                sx={{ 
                    alignItems: "flex-start",
                    display: "flex",
                    flexDirection: "column" 
                }}
            >
                <IconButton
                    color="primary"
                    sx={{
                        alignSelf: props.isBelowMd ? "center" : "start",
                        m: props.isBelowMd ? 0 : 0.2,
                    }}
                    onClick={handleClick}
                >
                    <img
                        alt="dropdown-icon"
                        style={{
                            width: "20px",
                            height: "11.36px",
                        }}
                        src={require("../../../../assets/icons/arrow-down.png")}
                    />
                </IconButton>
                {isCountrySelected &&
                    <IconButton
                        sx={{
                            alignSelf: props.isBelowMd ? "center" : "end",
                            m: props.isBelowMd ? 0 : 0.2,
                        }}
                        onClick={handleCancelClick}
                    >
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                }
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                classes={{
                    paper: 'MuiCustom-menu'
                }}
            > 
                {isLoading 
                    ?   <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
                    :   filters?.countries.map((c) => (
                            <MenuItem 
                                onClick={itemHandleClose} 
                                key={c.country_name} 
                                value={c.country_id}
                            >
                                {c.country_name}
                            </MenuItem>
                        ))
                }
                
            </Menu>
        </Box>
    );
};

export default SelectCountry;
