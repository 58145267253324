import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FrontPageCarousel from "../carousel";
import { Link } from "react-router-dom";

export default function FrontpageDentalBlock() {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const items = [
    { url: "/carousel/dental-1.jpg", title: "Filling" },
    { url: "/carousel/dental-2.jpg", title: "veneer" },
    { url: "/carousel/dental-3.jpg", title: "Whitening" },
    { url: "/carousel/dental-4.jpg", title: "Implants" },
  ];
  return (
    <Box
      sx={{
        maxWidth: "1210px",
        width: "95%",
        marginTop: isBelowMd ? "50px" : "100px",  
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        borderBottom: 7,
        borderColor: "#749BD6",
      }}
    >
      <Typography
        sx={{
          width: "100%",
          textAlign: "left",
          fontSize: isBelowMd ? "30px" : "50px",
          fontFamily: "Montserrat-Regular",
          color: "primary.main",
          pl: "20px"
        }}
      >
        Perfect Dental Treatments For You
      </Typography>
      <Box
        sx={{
          width: "100%",
          pl: "20px"
        }}
      >
        <Typography
          sx={{
            width: "90%",
            textAlign: "left",

            fontWeight: 400,
            fontSize: isBelowMd ? "20px" : "30px",
            fontFamily: "Montserrat-Regular",
            color: "primary.main",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </Typography>
      </Box>

      <FrontPageCarousel items={items} isBelowMd={isBelowMd} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{
            color: "inherit",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: 400,
              fontSize: "30px",
              fontFamily: "Montserrat-Regular",
              color: "primary.main",
            }}
          >
            See more
          </Typography>
          <Box
            sx={{
              padding: "0 10px",
            }}
          >
            <img
              alt="dropdown-icon"
              style={{
                width: "20px",
                height: "10.96px",
              }}
              src={require("../../assets/icons/arrow-down.png")}
            />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
