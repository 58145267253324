import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/elements/mainContainer";
import InputMultipleLines from "../../components/elements/inputMultipleLines";
import TreatmentListInput from "../../components/elements/treatmentsListInput";
import Dropdown from "../../components/elements/dropdown";
import DatePicker from "../../components/elements/datepicker";
import FormHeader from "../../components/elements/formHeader";
import FormFooter from "../../components/elements/formFooter";
import ContinueButton from "../../components/elements/continueButton";
import { appointmentTimes } from "../../constants";

export default function Schedule() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const navigate = useNavigate();

  const treatments = [
    { id: 1, value: "Anytime"},
    { id: 2, value: "Morning"},
    { id: 3, value: "Afternoon"},
    { id: 4, value: "Evening"},
    { id: 5, value: "Anytime"},
    { id: 6, value: "Morning"},
    { id: 7, value: "Afternoon"},
    { id: 8, value: "Evening"},
    { id: 9, value: "Anytime"},
    { id: 10, value: "Morning"},
    { id: 11, value: "Afternoon"},
    { id: 12, value: "Evening"}
  ];

  const handleClickContinue = () => {
    navigate("/appointment-patient");
  }

  return (
    <MainContainer>
      <FormHeader label={"Schedule Your Appointment"}/>
      <Box 
        sx={{ 
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: isPhone ? "60px" : "70px",
          backgroundColor: "white",
          border: 1,
          borderColor: "primary.main",
          px: isPhone ? "25px" : "45px",
          mb: "1px"
        }}
      >
        <Typography variant={isPhone ? "p" : "h5"} color="primary.main">
          1 - What and when
        </Typography>
      </Box>
      <DatePicker label="Appointment date"/>
      <Dropdown label="Appointment time" options={appointmentTimes}/>
      <InputMultipleLines label="Message to doctor"/>
      <TreatmentListInput label="Reason for visit" options={treatments}/>
      <FormFooter justify="flex-end">
        <ContinueButton handleClick={handleClickContinue} />
      </FormFooter>
    </MainContainer>
  );
}
