import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendUrl from "../../config";

export const fetchCities = createAsyncThunk("fetchCitiesResults", async () => {
	const res = await fetch(`${backendUrl}/cities`); // + country_id
  
	const data = await res.json();
  
	if (res.ok) {
	  return data;
	} else {
	  throw new Error("Failed to fetch cities");
	}
  });
  

const citySlice = createSlice({
  	name: "cities",
  	initialState: [
		{ name: "City 1" }
	],
	reducers: {
		updateCityList: (state, action) => {

			state.push();
		}
	}
});

export const { updateCityList } = citySlice.actions;

export default citySlice.reducer; 