import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./carousel.css";

export default function FrontPageCarousel({ items, isBelowMd }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  return (
    <div 
      style={{ 
        width: "100%", 
        height: isBelowMd ? "250px" : "300px", 
        marginTop: "30px" 
      }}
    >
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        infinite={true}
        containerClass="carousel-container"
        //removeArrowOnDeviceType={["tablet", "mobile"]}
        sliderClass="react-multi-carousel-track"
        itemClass="carousel-item"
      >
        {items.map((item) => {
          return (
            <div 
              className="image-wrapper" 
              key={item.title}
              style={{  
                height: isBelowMd ? "250px" : "300px"
              }}
            >
              <img alt="treatment-type" src={item.url}></img>
              <h3 className="image-title">{item.title}</h3>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
