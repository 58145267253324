import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import backendUrl from "../../config";

export const fetchTreatmentCategoriesWithTypes = createAsyncThunk(
  "fetchTreatmentCategoriesWithTypesResults",
  async () => {
    const res = await fetch(`${backendUrl}/treatment-categories`);

    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Failed to fetch countries");
    }
  }
);

const countrySlice = createSlice({
  name: "treatmentCategoriesWithTypes",
  initialState: {
    categoriesList: [],
    selectedTreatmentTypeId: 0,
    selectedTreatmentTypeDisplay: "Select a Treatment",
    isTreatmentSelected: false,
    fetchingCategories: false,
  },
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
    getCategories: (state, action) => {
      let treatmentCategoriesWithTypes = action.payload;
      state.push(treatmentCategoriesWithTypes);
    },
    setSelectedTreatment: (state, action) => {
      const selectedTreatmentTypeId = action.payload;
      //state.push(selectedTreatmentTypeId);
      state.selectedTreatmentTypeId = selectedTreatmentTypeId;
    },
    setSelectedTreatmentDisplay: (state, action) => {
      const selectedTreatmentTypeDisplay = action.payload;
      //state.push(selectedTreatmentTypeId);
      state.selectedTreatmentTypeDisplay = selectedTreatmentTypeDisplay;
    },
    setIsTreatmentSelected: (state, action) => {
      const isTreatmentSelected = action.payload;
      state.isTreatmentSelected = isTreatmentSelected;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreatmentCategoriesWithTypes.fulfilled, (state, action) => {
      state.categoriesList = action.payload;
      if (state.fetchingCategories) {
        state.fetchingCategories = false;
      }
    });
    builder.addCase(fetchTreatmentCategoriesWithTypes.pending, (state, action) => {
      state.categoriesList = {};
      if (!state.fetchingCategories) {
        state.fetchingCategories = true;
      }
    });
  },
});

export const { getCategories, setSelectedTreatment, setSelectedTreatmentDisplay, setIsTreatmentSelected } =
  countrySlice.actions;

export default countrySlice.reducer;
