import React from "react";
import { TextField } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      "&:hover": {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "black",
        backgroundColor: "white",
      },
      "&.Mui-focused": {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#104583",
        backgroundColor: "white",
      },
      backgroundColor: "white",
      borderRadius: "50px",
      padding: "10px 20px",
    },
    "& .MuiFilledInput-input": {
      padding: "0",
    },
  },
  input: {
    "&:-webkit-autofill": {
      "border-radius": "unset !important",
      "-webkit-box-shadow": "0 0 0 100px #FFF inset",
      "-webkit-text-fill-color": "inherit !important",
    },
  },
}));

export default function CustomInput({ id, name, value, onChange, placeholder, type = "text", ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  return (
    <TextField
      id={id}
      className={classes.root}
      name={name}
      value={value}
      placeholder={placeholder ? placeholder : null}
      onChange={onChange}
      variant="filled"
      type={type}
      InputProps={{
        disableUnderline: true,
        classes: { input: classes.input },
      }}
      style={{ width: isPhone ? props.phoneWidth : props.desktopWidth }}
    />
  );
}
