import React, { useEffect, useState } from "react";
import MainContainer from "../components/elements/mainContainer";
import RegisterClinicForm from "../components/registerClinicForm";
import RegisterClinicFormStep2 from "../components/registerClinicFormStep2";
import { useSelector } from "react-redux";

export default function RegisterClinicPage() {
  const userInfo = useSelector((state) => ({
    loggedIn: state.user.loggedIn,
    loading: state.user.loading,
    clinics: state.user.userInfo.clinics,
  }));

  return (
    <MainContainer>
      {!userInfo.clinics && !userInfo.loading && <RegisterClinicForm />}

      {userInfo.clinics && userInfo.clinics[0].registration_status === "APPROVED" && <RegisterClinicFormStep2 />}
    </MainContainer>
  );
}
