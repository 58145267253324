import React, { useEffect } from "react";
import { useFormik } from "formik";

import Input from "../elements/input";
import PhoneInputComponent from "../elements/phoneInput";
import FormFooter from "../elements/formFooter";
import ContinueButton from "../elements/continueButton";
import { useNavigate, useLocation } from "react-router";
import FormHeader from "../elements/formHeader";
import { useSelector, useDispatch } from "react-redux";
import { login, registerUser } from "../../redux/reducers/userSlice";
import BackendErrorMessage from "../backendErrorMessage";
import Dropdown from "../elements/dropdown";
import { fetchCountries } from "../../redux/reducers/countrySlice";

export default function RegisterUserForm() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const countries = useSelector((state) => state.countries.countriesList);

  const backendErrorMessage = useSelector((state) => state.user.error);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeatPassword: "",
      country: "",
      phone: "",
    },
    onSubmit: (values) => {
      const { email, password, firstName, lastName, country, phone } = values;
      dispatch(
        registerUser({ email: email, password: password, first_name: firstName, last_name: lastName, country, phone })
      );
    },
  });

  useEffect(() => {
    if (loggedIn && location.state.fromRegisterClinic) {
      navigate("/registerclinic");
    } else if (loggedIn) {
      navigate("/");
    }
  }, [loggedIn]);

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormHeader label={"Register User Form"} />
        <Input label="Email address" name={"email"} onChange={formik.handleChange} value={formik.values.name} />
        <Input
          label="Password"
          type="password"
          name={"password"}
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <Input
          label="Repeat Password"
          name={"repeatPassword"}
          onChange={formik.handleChange}
          value={formik.values.repeatPassword}
          type="password"
        />
        <Input label="First Name" name={"firstName"} onChange={formik.handleChange} value={formik.values.firstName} />
        <Input label="Last Name" name={"lastName"} onChange={formik.handleChange} value={formik.values.lastName} />
        <Dropdown
          label={"Country"}
          name="country"
          options={countries}
          onChange={formik.handleChange}
          value={formik.values.country}
        />
        <PhoneInputComponent label="Phone Number" onChange={formik.setFieldValue} value={formik.values.phone} />

        <FormFooter justify="flex-end">
          <ContinueButton />
        </FormFooter>
        {backendErrorMessage && <BackendErrorMessage message={backendErrorMessage} />}
      </form>
    </>
  );
}
