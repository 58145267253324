import { Box, Typography, Button } from "@mui/material";

export default function FormFooter({ children, ...props }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: props.justify ? props.justify : "space-between",
        alignItems: "center",
        height: "70px",
        backgroundColor: "primary.main",
        borderRadius: "0px 0px 20px 20px",
        px: "45px",
      }}
    >
      {children}
    </Box>
  );
}
