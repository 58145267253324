import React from "react";

import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import SearchBar from "./Items/SearchBar";
import Menu from "./Items/Menu";

const Content = ({ anchor, toggleDrawer }) => {
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    return (
        <Stack
            sx={{
                width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
                alignItems: "end",
                color: "primary.main",
            }}
            role="presentation"
            // onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
            minWidth={isPhone ? "60vw" : 400}
        >
            <SearchBar />
            <Menu />
            <Button
                variant="contained"
                size="large"
                sx={{
                    borderRadius: "16px",
                    fontSize: "1.2rem",
                    mt: 2,
                    mx: 1.5,
                }}
            >
                Log in
            </Button>
            <Button
                variant="contained"
                color="primary"
                style={{ maxWidth: "378px", flexGrow: 1 }}
                startIcon={<AddIcon />}
                size="large"
                sx={{
                    borderRadius: "16px",
                    fontSize: "1.2rem",
                    mt: 2,
                    mx: 1.5,
                }}
            >
                Add your clinic
            </Button>
        </Stack>
    );
};

export default Content;
